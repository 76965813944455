import {
    CLEAR,
    LOGIN_REQUEST,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILED,
    LOGIN_SET_AUTHENTICATE,
    LOGIN_OPEN_PINCODE_MODAL,
    LOGIN_SAVE_2FA_AUTH_INFO,
    PASSWORD_TOO_WEAK,
} from './types';

const initState = {
    fetching: false,
    isAuthenticate: false,
    isPinCodeModalOpen: false,
    twoFAuthInfo: {
        sessionId: '',
        url: '',
    },
    isPasswordWeak: false,
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR:
            return {
                ...initState,
            };

        case LOGIN_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                isAuthenticate: true,
            };

        case LOGIN_SET_AUTHENTICATE:
            return {
                ...state,
                fetching: false,
                isAuthenticate: action.payload,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                fetching: false,
                isAuthenticate: false,
            };

        case LOGIN_OPEN_PINCODE_MODAL:
            return {
                ...state,
                isPinCodeModalOpen: action.payload,
            };

        case LOGIN_SAVE_2FA_AUTH_INFO:
            return {
                ...state,
                twoFAuthInfo: {
                    sessionId: action.payload.sessionId,
                    url: action.payload.url,
                },
            };

        case PASSWORD_TOO_WEAK:
            return {
                ...state,
                isPasswordWeak: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
