import {
    SETUP_STRIPE_INTENT_SUCCESSFUL,
    SET_ADYEN_3DS_ACTION,
    SET_ADYEN_3DS_REQUIRED,
    REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL,
    RESET_STRIPE_CLIENT_SECRET,
    RESET_STRIPE,
    GET_PUB_KEY_SUCCESS,
    GET_PUB_KEY_INTENT_SUCCESSFUL,
    SET_PAYMENT_PROVIDER,
} from './types';

const initState = {
    paymentProvider: '',
    stripe: {
        pubKey: '',
        clientSecret: '',
    },
    adyen: {
        nextAction: {},
        status3ds: '',
        is3dsRequired: null,
        pubKey: '',
    },
};

const reducer = (state = initState, { type, payload }) => {
    switch (type) {
        case RESET_STRIPE: {
            return initState;
        }

        case RESET_STRIPE_CLIENT_SECRET: {
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    clientSecret: '',
                },
            };
        }

        case SETUP_STRIPE_INTENT_SUCCESSFUL: {
            const { pubKey, clientSecret, setupIntentId } = payload;
            return {
                ...state,
                stripe: {
                    clientSecret,
                    pubKey: pubKey !== undefined ? pubKey : state.stripe.pubKey,
                    setupIntentId,
                },
            };
        }

        case SET_ADYEN_3DS_REQUIRED: {
            const { is3dsRequired } = payload;
            return {
                ...state,
                adyen: {
                    ...state.adyen,
                    is3dsRequired,
                },
            };
        }

        case SET_ADYEN_3DS_ACTION: {
            const { nextAction } = payload;
            return {
                ...state,
                adyen: {
                    ...state.adyen,
                    nextAction,
                },
            };
        }

        case REQUEST_ADYEN_SYNCHRONIZE_SUCCESSFUL: {
            return {
                ...state,
                adyen: {
                    ...state.adyen,
                    status3ds: payload,
                },
            };
        }

        case GET_PUB_KEY_SUCCESS: {
            const { pubKey } = payload;
            return {
                ...state,
                stripe: {
                    clientSecret: '',
                    pubKey,
                },
            };
        }

        case GET_PUB_KEY_INTENT_SUCCESSFUL: {
            const { pubKey } = payload;
            return {
                ...state,
                adyen: {
                    ...state.adyen,
                    pubKey,
                },
            };
        }

        case SET_PAYMENT_PROVIDER: {
            return {
                ...state,
                paymentProvider: payload,
            };
        }

        default:
            return state;
    }
};

export default reducer;
