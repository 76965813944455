import { all, takeLatest } from 'redux-saga/effects';

import { BILLING_LIST_REQUEST, BILLING_SET_FILTER } from '../../redux/billing/types';

import list from './list';

export default function*() {
    yield all([
        takeLatest(BILLING_LIST_REQUEST, list),
        takeLatest(BILLING_SET_FILTER, list)
    ]);
}
