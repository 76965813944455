export const CLEAR = '@login/CLEAR';
export const LOGIN_REQUEST = '@login/LOGIN_REQUEST';
export const LOGIN_SUCCESSFUL = '@login/LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = '@login/LOGIN_FAILED';
export const LOGIN_SET_AUTHENTICATE = '@login/LOGIN_SET_AUTHENTICATE';
export const LOGOUT_REQUEST = '@login/LOGOUT_REQUEST';
export const TWO_FA_LOGIN_REQUEST = '@login/TWO_FA_LOGIN_REQUEST';
export const LOGIN_OPEN_PINCODE_MODAL = '@login/LOGIN_OPEN_PINCODE_MODAL';
export const LOGIN_SAVE_2FA_AUTH_INFO = '@login/LOGIN_SAVE_2FA_AUTH_INFO';
export const PASSWORD_TOO_WEAK = '@login/PASSWORD_TOO_WEAK';
