import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { userGetProfileServicesWithStatusesSuccessful } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*() {
    try {
        const response = yield call(UserClient.getUserServices);
        const userProfilesWithServices = response ? response.profiles : [];
        yield put(
            userGetProfileServicesWithStatusesSuccessful({
                userProfilesWithServices,
            })
        );
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            console.error('getUserServicesStatus', error);
            yield put(messagesShow('internalServerError', 'error'));
        }
    }

    return null;
}
