import moment from 'moment';

export const formattedArraySuggestions = suggestions =>
    suggestions.map(suggestion => ({
        value: suggestion.name,
        label: suggestion.name,
    }));

export const formatAutoSuggestSelectValueFromArray = (value, array, key) => {
    const { name = '' } = array.find(obj => value === obj[key]) || {};
    return {
        value: value || '',
        label: name,
    };
};

export const rangeYearsAndMonthsFrom2015toCurrentDate = () => {
    const currentDate = moment();
    const startDate = 2015;

    const years = [];
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    for (let i = startDate; i <= currentDate.year(); i += 1) {
        years.push(i);
    }
    return years.map(year => ({
        year,
        months: months.filter(
            month => year !== currentDate.year() || (year === currentDate.year() && month <= currentDate.month())
        ),
    }));
};
