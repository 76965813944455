import moment from 'moment';

import { rangeYearsAndMonthsFrom2015toCurrentDate } from '../../helpers/array';
import { CLEAR, BILLING_LIST_REQUEST, BILLING_LIST_SUCCESSFUL, BILLING_LIST_FAILED, BILLING_SET_FILTER } from './types';

const initState = {
    fetching: false,
    list: null,
    year: null,
    month: null,
    profile: '',
    profiles: [],
    listFilter: rangeYearsAndMonthsFrom2015toCurrentDate(),
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR:
            return {
                ...initState,
            };

        case BILLING_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case BILLING_LIST_SUCCESSFUL: {
            const { trips: dataTrips, others: dataOthers, old } = action.payload;

            const datas = dataTrips.Billing ? dataTrips.Billing : dataTrips;

            const trips = datas.map(item => {
                const date = moment(item.trip.tripStartDate);
                return {
                    ...item,
                    year: date.year(),
                    month: date.month(),
                };
            });
            trips.sort((a, b) => {
                if (a.trip.tripStartDate < b.trip.tripStartDate) {
                    return 1;
                }
                if (a.trip.tripStartDate > b.trip.tripStartDate) {
                    return -1;
                }
                return 0;
            });
            const others = dataOthers.map(item => {
                const date = moment(item.invoice.billingDate);
                return {
                    ...item,
                    year: date.year(),
                    month: date.month(),
                };
            });
            others.sort((a, b) => {
                if (a.invoice.billingDate < b.invoice.billingDate) {
                    return 1;
                }
                if (a.invoice.billingDate > b.invoice.billingDate) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state,
                fetching: false,
                list: {
                    trips,
                    others,
                    old,
                },
            };
        }

        case BILLING_LIST_FAILED:
            return {
                ...state,
                fetching: false,
            };

        case BILLING_SET_FILTER:
            return {
                ...state,
                ...action.payload,
                fetching: true,
            };

        default:
            return state;
    }
};

export default reducer;
