import { createSelector } from 'reselect';

const stateSelector = state => state.bookings;

export const isFetchingModelsByStationSelector = createSelector(stateSelector, state => state.fetchingModelsByStation);
export const isFetchingModelsByStationFinishedSelector = createSelector(
    stateSelector,
    state => state.fetchingModelsByStationFinished
);
export const isFetchingModelAssetsSelector = createSelector(stateSelector, state => state.isFetchingModelAssets);
export const selectedModelAssetsSelector = createSelector(stateSelector, state => state.selectedModelAssets);

export const isAddingSelector = createSelector(stateSelector, state => state.isAdding);

export const paymentStatusSelector = createSelector(stateSelector, state => state.paymentStatus);
export const redirectUrlSelector = createSelector(stateSelector, state => state.redirectUrl);

export const isConfirmAddingDialogOpenedSelector = createSelector(
    stateSelector,
    state => state.isConfirmAddingDialogOpened
);

export const newBookingDataSelector = createSelector(stateSelector, state => state.newBooking);
export const bookingSelector = createSelector(stateSelector, state => state.booking);

export const newBookingCurrentStepSelector = createSelector(
    [newBookingDataSelector],
    newBooking => (newBooking && newBooking.currentStep) || 1
);

export const selectedProfileIdSelector = createSelector(
    [newBookingDataSelector],
    newBooking => (newBooking && newBooking.selectedProfileId) || null
);

export const selectedServiceIdSelector = createSelector(
    [newBookingDataSelector],
    newBooking => (newBooking && newBooking.selectedServiceId) || null
);

export const selectedCityIdSelector = createSelector(
    [newBookingDataSelector],
    newBooking => (newBooking && newBooking.selectedCityId) || null
);

export const isFetchingSBRequestsSelector = createSelector(stateSelector, state => state.fetchingSBRequests);
export const isFetchingSBRequestByIDSelector = createSelector(stateSelector, state => state.fetchingSBRequestByID);
export const isFetchingPaidSBRequestsSelector = createSelector(stateSelector, state => state.fetchingPaidSBRequests);
export const showRequestStatusSelector = createSelector(stateSelector, state => state.showRequestStatus);

export const goToAccountDialogDataSelector = createSelector(stateSelector, state => state.goToAccountDialog);

export const isPaidSBRequestsSelector = createSelector(stateSelector, state => state.isPaidSBRequests);

export const isCancellingSelector = createSelector(stateSelector, state => state.isCancelling);

export const myBookingsSelector = createSelector(stateSelector, state => state.myBookings);
export const bookingByIDSelector = createSelector(stateSelector, state => state.bookingByID);

export const myBookingsDataSelector = createSelector(
    [myBookingsSelector],
    myBookings => (myBookings && myBookings.data) || []
);

export const myBookingsOrderSelector = createSelector(
    [myBookingsSelector],
    myBookings => myBookings && myBookings.order
);
export const myBookingsOrderBySelector = createSelector(
    [myBookingsSelector],
    myBookings => myBookings && myBookings.orderBy
);

export const allCitiesSelector = createSelector(stateSelector, state => state.allCities);
