import { createSelector } from 'reselect';

const stateSelector = state => state.invoicesReplay;

export const invoicesReplayDataSelector = createSelector(stateSelector, state => state.data);
export const invoicesReplayListSelector = createSelector(
    stateSelector,
    state => (state.data && state.data.v_invoices) || null
);
export const invoicesReplayCurrencySelector = createSelector(stateSelector, state => state.data.v_amount_currency);
export const invoicesReplayIsFetchingSelector = createSelector(stateSelector, state => state.fetching);
export const shouldRedirectToInvoicesSelector = createSelector(stateSelector, state => state.shouldRedirectToInvoices);
export const isPaymentProcessingSelector = createSelector(stateSelector, state => state.isPaymentProcessing);
export const displayRedirectToInvoices = createSelector(stateSelector, state => state.displayRedirectToInvoices);
