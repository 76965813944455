import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { userResetPasswordSuccessful, userResetPasswordFailed } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload: { token, password } }) {
    try {
        yield call(UserClient.resetLostPassword, token, password);
        yield put(userResetPasswordSuccessful());
        yield put(messagesShow('resetPasswordSuccefull', 'success'));
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else if (error.code === 424) {
            yield put(userResetPasswordFailed());
            yield put(messagesShow('loginNotExist', 'error'));
        } else {
            yield put(userResetPasswordFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
