import {
    ACTION_GET_STATION_INFORMATION,
    ACTION_SET_STATION_INFORMATION_SUCCESS,
    ACTION_SET_STATION_INFORMATION_FAILURE,
    ACTION_SET_STATION_ACCESS_INSTRUCTIONS,
} from './types';

const initState = {
    fetching: false,
    stationInformation: {
        name: '',
        address: '',
        description: [],
        openingHours: {},
    },
    stationInformationError: '',
    stationAccessInstructions: {
        assets: [],
    },
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_GET_STATION_INFORMATION:
            return {
                ...state,
                fetching: true,
            };

        case ACTION_SET_STATION_INFORMATION_SUCCESS:
            return {
                ...state,
                stationInformation: action.payload,
                fetching: false,
            };

        case ACTION_SET_STATION_INFORMATION_FAILURE:
            return {
                ...state,
                stationInformationError: action.payload,
                fetching: false,
            };
        case ACTION_SET_STATION_ACCESS_INSTRUCTIONS:
            return {
                ...state,
                stationAccessInstructions: {
                    ...state.stationAccessInstructions,
                    assets: action.payload,
                },
            };

        default:
            return state;
    }
};

export default reducer;
