import { call, select, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { invoicesReplayDataSelector } from '../../selectors/invoicesReplay';
import { setupStripeIntentSuccessful } from '../../redux/app/actions';
import { setDisplayRedirectToInvoices, setIsPaymentProcessing } from '../../redux/invoicesReplay/actions';
import { messagesShow } from '../../redux/messages/actions';
import { USER_SET_FAILED_INVOICES_PROCESSING } from '../../redux/user/types';

export function* payInvoicesSaga() {
    yield put(setIsPaymentProcessing(true));
    const data = yield select(invoicesReplayDataSelector);
    try {
        const { status, pspClientSecret, pspPublishableKey } = yield call(UserClient.onPayInvoices, data);
        if (status === 'FAILED') {
            throw new Error('Replay payment failed. Please try again.');
        }
        yield put(setupStripeIntentSuccessful({ clientSecret: pspClientSecret, pubKey: pspPublishableKey }));
        yield put(setDisplayRedirectToInvoices(false));
    } catch (error) {
        const errorMsg = error.message && typeof error.message === 'string' ? error.message : 'internalServerError';
        yield put(messagesShow(errorMsg, 'error'));
    }
}

export function* payFailedInvoicesSaga() {
    try {
        yield call(UserClient.onPayFailedInvoices);
        yield put({ type: USER_SET_FAILED_INVOICES_PROCESSING });
        yield put(messagesShow('InvoicesProcessingOKMessage', 'success'));
    } catch (error) {
        yield put(messagesShow(error, 'error'));
    }
}
