import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import {
    sortArrayByProfileType,
    CONST_PROFILE_TYPE_SINGLE,
    CONST_PROFILE_TYPE_BUSINESS,
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_NOT_ACTIVE,
} from '../../helpers/user';
import UserClient from '../../api/UserClient';
import { userGetSuccessful, userGetFailed, setAllPlans } from '../../redux/user/actions';
import { getPubKeyIntentSuccessful } from '../../redux/app/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import { setLanguage } from '../../components/Contexts/Locale';
import { invoicesReplayDataSelector } from '../../selectors/invoicesReplay';
import { setDisplayRedirectToInvoices } from '../../redux/invoicesReplay/actions';
import { getInvoicesReplayDataSaga } from '../invoicesReplay/get';
import fetchCities from '../bookings/fetchCitiesRequest';
import { paymentProviderSelector } from '../../selectors/app';

export const extractDocuments = userDocuments => {
    const validDocuments = userDocuments.documents.filter(document => !!document.documentType.type);
    const documents = {};
    validDocuments.forEach(userDocument => (documents[userDocument.documentType.type] = userDocument));
    return documents;
};

export const extractDocumentTypes = userDocuments =>
    userDocuments.documentTypes.filter(documentType => !!documentType.type);

export default function*() {
    try {
        const isApiv5 = UserClient.apiConf.baseEndpoint.indexOf('apiv5') !== -1;

        const apiv5Calls = isApiv5
            ? [call(UserClient.fetchAllPlans), call(UserClient.fetchPlanFees), call(UserClient.getPasswordStrength)]
            : [];

        yield call(fetchCities);

        const [user, userProfile, userDocuments, cities, subscriptionPlans, planFees, passwordStrength] = yield all([
            call(UserClient.getUser),
            call(UserClient.getUserProfile),
            call(UserClient.getUserDocuments),
            call(UserClient.getCities),
            ...apiv5Calls,
        ]);
        let subscriptions = null;

        if (isApiv5) {
            const { profileId } = userProfile;
            yield put(setAllPlans(subscriptionPlans));
            subscriptions = yield call(UserClient.getUserSubscriptions, profileId);
            subscriptions = subscriptions.map(subscription => {
                const plan = subscriptionPlans.find(p => p.id === subscription.planId);

                plan.product = planFees.find(f => f.id === plan.productId);
                return {
                    ...subscription,
                    plan,
                    status:
                        subscription.expiryDate && moment(subscription.expiryDate).isAfter(moment())
                            ? SUBSCRIPTION_STATUS_ACTIVE
                            : SUBSCRIPTION_STATUS_NOT_ACTIVE,
                };
            });
        }

        const documents = extractDocuments(userDocuments);
        const documentTypes = extractDocumentTypes(userDocuments);
        const { documentByService } = userDocuments;

        // if the user has no entityId (multiprofile fleets),
        // then we use the entityId of the personal profile (single)
        let { entityId } = user;
        let businessProfile;
        let paymentDetailsBusiness;
        if (!entityId) {
            const personalProfile =
                user.profiles && user.profiles.find(profile => profile.type === CONST_PROFILE_TYPE_SINGLE);
            entityId = personalProfile && personalProfile.entityId;

            businessProfile =
                user.profiles && user.profiles.find(profile => profile.type === CONST_PROFILE_TYPE_BUSINESS);
        }

        const paymentDetails = yield call(UserClient.getPaymentDetails, entityId);
        if (businessProfile) {
            paymentDetailsBusiness = yield call(UserClient.getPaymentDetails, businessProfile.entityId);
        }

        const { profiles } = user;
        if (profiles && profiles[0].status === 'OUTSTANDING_BALANCE') {
            yield call(getInvoicesReplayDataSaga);
            const data = yield select(invoicesReplayDataSelector);
            if (Object.keys(data).length > 0) {
                const { v_invoices: invoices } = data;

                if (invoices.length > 0) {
                    yield put(setDisplayRedirectToInvoices(true));
                }
            }
        }

        const paymentProvider = yield select(paymentProviderSelector);

        if (paymentProvider && paymentProvider !== 'stripe') {
            try {
                const pubKey = yield call(UserClient.getPublishableKeyForIntent);
                yield put(getPubKeyIntentSuccessful({ pubKey }));
            } catch (err) {
                yield console.error('Pub Key Intent error', err);
            }
        }

        const services = (cities || []).reduce((acc, city) => [...acc, ...city.services], []);
        user.profile = userProfile;

        user.profiles = sortArrayByProfileType(user.profiles);

        user.passwordStrength = passwordStrength;

        yield put(
            userGetSuccessful({
                user,
                paymentDetails,
                paymentDetailsBusiness,
                documents,
                documentTypes,
                documentByService,
                services,
                subscriptions,
            })
        );
        setLanguage(user.locale);
        return user;
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(userGetFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }

    return null;
}
