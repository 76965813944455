import { call, put, all } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { messagesShow } from '../../redux/messages/actions';
import { userUploadDocumentAttachementAction } from '../../redux/user/actions';
import {
    USER_SAVE_DOCUMENTS_FORM_REQUEST,
    USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED,
    USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS,
    USER_GET_REQUEST,
} from '../../redux/user/types';

export default function* saveDoc({ payload }) {
    try {
        const { form, attachements } = payload;

        // first we save the doc informations
        const { documentType, document } = form;
        yield put({ type: USER_SAVE_DOCUMENTS_FORM_REQUEST });
        const documentToSave = {
            documentType,
            documentNumber: document.documentNumber,
            expirationDate: document.expirationDate,
            issuingCountry: document.issuingCountry,
            issuingOffice: document.issuingOffice,
            issuingDate: document.issuingDate,
            dlClass: document.dlClass,
        };
        let result = {};
        if (document) {
            result = yield call(UserClient.createDocument, documentToSave);
        }
        // then we save the doc attachments (pictures)
        // if the doc has just been created, we need to update the url to send the attachments, according to the previous response
        attachements.map(item => {
            const itemToSend = { ...item };
            if (itemToSend.uploadQueue.endpoint.url === null) {
                const uploadUrl = result.uploadUrls.find(r => r.name === itemToSend.uploadQueue.endpoint.name);
                itemToSend.uploadQueue.endpoint.url = uploadUrl.url;
            }
            return itemToSend;
        });
        // do the call to send the pictures
        if (attachements.length !== 0) {
            yield all(attachements.map(item => put(userUploadDocumentAttachementAction(item))));
        }

        // SUCCESS \o/
        yield put({ type: USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS });
        if (attachements.length === 0) {
            yield put(messagesShow('editOk', 'success'));
            yield put({ type: USER_GET_REQUEST });
        }
    } catch (e) {
        yield put(messagesShow('editNotOk', 'error'));
        yield put({ type: USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED });
        yield put({ type: USER_GET_REQUEST });
    }
}
