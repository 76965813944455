import {
    NEW_BOOKING_CLEAR,
    NEW_BOOKING_SET_CURRENT_STEP,
    NEW_BOOKING_UPDATE_DATA,
    NEW_BOOKING_FETCH_MODELS_BY_STATION,
    NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL,
    NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED,
    SET_IS_FETCHING_MODELS_BY_STATION_FINISHED,
    GET_MODEL_ASSETS,
    FETCH_MODEL_ASSETS_FINISHED,
    UPDATE_GO_TO_ACCOUNT_DIALOG,
    NEW_BOOKING_CREATE_SB_REQUEST,
    NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL,
    NEW_BOOKING_CREATE_SB_REQUEST_FAILED,
    NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED,
    BOOKINGS_FETCH_SB_REQUESTS,
    BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL,
    BOOKINGS_FETCH_SB_REQUESTS_FAILED,
    BOOKINGS_CANCEL_SB_REQUEST,
    BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL,
    BOOKINGS_CANCEL_SB_REQUEST_FAILED,
    BOOKINGS_SET_SB_REQUESTS_ORDER,
    BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL,
    BOOKINGS_FETCH_PAID_REQUESTS_FAILED,
    BOOKINGS_FETCH_PAID_REQUESTS,
    BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL,
} from './types';

const initState = {
    fetchingModelsByStation: false,
    fetchingModelsByStationFinished: false,
    isFetchingModelAssets: false,
    goToAccountDialog: {
        open: false,
        message: '',
    },
    selectedModelAssets: null,
    isAdding: false,
    isConfirmAddingDialogOpened: false,
    fetchingSBRequests: false,
    fetchingSBRequestByID: false,
    fetchingPaidSBRequests: false,
    isPaidSBRequests: false,
    isCancelling: false,
    paymentStatus: null,
    redirectUrl: null,
    newBooking: {
        currentStep: 1,
        selectedProfileId: null,
        selectedServiceId: null,
        profileStatusForSelectedService: null,
        selectedCityId: null,
        selectedCityName: '',
        selectedCityTermsAccepted: false,
        selectedStartDate: null,
        selectedEndDate: null,
        stations: [],
        selectedStationId: null,
        selectedModelId: null,
        selectedModelName: '',
        notes: '',
        bookingDistanceUnit: '',
        displayEstimatedPrice: false,
    },
    myBookings: {
        data: [],
        order: 'asc',
        orderBy: 'startDate',
    },
    booking: {
        data: {},
    },
    allCities: [],
    showRequestStatus: false,
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case NEW_BOOKING_CLEAR:
            return {
                ...state,
                newBooking: {
                    ...initState.newBooking,
                },
            };

        case NEW_BOOKING_SET_CURRENT_STEP:
            return {
                ...state,
                newBooking: {
                    ...state.newBooking,
                    currentStep: action.payload,
                },
            };

        case NEW_BOOKING_UPDATE_DATA: {
            let newValues;
            if (Array.isArray(action.payload)) {
                newValues = action.payload.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {});
            } else {
                const { name, value } = action.payload;
                newValues = {
                    [name]: value,
                };
            }
            return {
                ...state,
                newBooking: {
                    ...state.newBooking,
                    ...newValues,
                },
            };
        }

        case NEW_BOOKING_FETCH_MODELS_BY_STATION:
            return {
                ...state,
                fetchingModelsByStation: true,
            };

        case NEW_BOOKING_FETCH_MODELS_BY_STATION_SUCCESSFUL: {
            const { stations } = action.payload;
            return {
                ...state,
                fetchingModelsByStation: false,
                fetchingModelsByStationFinished: true,
                newBooking: {
                    ...state.newBooking,
                    stations,
                },
            };
        }

        case NEW_BOOKING_FETCH_MODELS_BY_STATION_FAILED:
            return {
                ...state,
                fetchingModelsByStation: false,
                fetchingModelsByStationFinished: true,
                newBooking: {
                    ...state.newBooking,
                    stations: [],
                },
            };

        case SET_IS_FETCHING_MODELS_BY_STATION_FINISHED:
            return {
                ...state,
                fetchingModelsByStationFinished: action.value,
            };

        case GET_MODEL_ASSETS:
            return {
                ...state,
                isFetchingModelAssets: true,
            };

        case FETCH_MODEL_ASSETS_FINISHED:
            return {
                ...state,
                selectedModelAssets: (action && action.payload) || null,
                isFetchingModelAssets: false,
            };

        case UPDATE_GO_TO_ACCOUNT_DIALOG:
            return {
                ...state,
                goToAccountDialog: { ...state.goToAccountDialog, ...action.payload },
            };

        case NEW_BOOKING_CREATE_SB_REQUEST:
            return {
                ...state,
                isAdding: true,
                showRequestStatus: false,
            };

        case NEW_BOOKING_CREATE_SB_REQUEST_SUCCESSFUL:
            return {
                ...state,
                ...action.payload,
                showRequestStatus: true,
                isConfirmAddingDialogOpened: true,
                isAdding: false,
            };

        case NEW_BOOKING_CREATE_SB_REQUEST_FAILED:
            return {
                ...state,
                isAdding: false,
                showRequestStatus: true,
            };

        case NEW_BOOKING_SET_CONFIRM_ADDING_DIALOG_OPENED:
            return {
                ...state,
                isConfirmAddingDialogOpened: action.payload,
            };

        case BOOKINGS_FETCH_SB_REQUEST_BY_ID:
            return {
                ...state,
                fetchingSBRequestByID: true,
            };

        case BOOKINGS_FETCH_SB_REQUEST_BY_ID_SUCCESSFUL: {
            const { data } = action.payload;
            return {
                ...state,
                fetchingSBRequestByID: false,
                booking: {
                    ...state.booking,
                    data,
                },
            };
        }
        case BOOKINGS_FETCH_SB_REQUEST_BY_ID_FAILED:
            return {
                ...state,
                fetchingSBRequestByID: false,
            };

        case BOOKINGS_FETCH_SB_REQUESTS:
            return {
                ...state,
                fetchingSBRequests: true,
            };

        case BOOKINGS_FETCH_SB_REQUESTS_SUCCESSFUL: {
            const { data } = action.payload;
            return {
                ...state,
                fetchingPaidSBRequests: false,
                fetchingSBRequests: false,
                myBookings: {
                    ...state.myBookings,
                    data,
                },
            };
        }

        case BOOKINGS_FETCH_SB_REQUESTS_FAILED:
            return {
                ...state,
                fetchingSBRequests: false,
            };

        case BOOKINGS_CANCEL_SB_REQUEST:
            return {
                ...state,
                isCancelling: true,
            };

        case BOOKINGS_CANCEL_SB_REQUEST_SUCCESSFUL:
            return {
                ...state,
                isCancelling: false,
            };

        case BOOKINGS_CANCEL_SB_REQUEST_FAILED:
            return {
                ...state,
                isCancelling: false,
            };

        case BOOKINGS_FETCH_PAID_REQUESTS:
            return {
                ...state,
                fetchingPaidSBRequests: true,
            };

        case BOOKINGS_FETCH_PAID_REQUESTS_SUCCESSFUL: {
            const { data, isPaidSBRequests } = action.payload;
            return {
                ...state,
                fetchingPaidSBRequests: false,
                isPaidSBRequests,
                myBookings: {
                    ...state.myBookings,
                    data,
                },
            };
        }

        case BOOKINGS_FETCH_PAID_REQUESTS_FAILED:
            return {
                ...state,
                fetchingPaidSBRequests: false,
            };

        case BOOKINGS_SET_SB_REQUESTS_ORDER: {
            const { order, orderBy } = action.payload;
            return {
                ...state,
                myBookings: {
                    ...state.myBookings,
                    order,
                    orderBy,
                },
            };
        }

        case BOOKINGS_FETCH_CITIES_REQUEST_SUCCESSFUL:
            return {
                ...state,
                allCities: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
