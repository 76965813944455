import { call, put, select } from 'redux-saga/effects';
import UserClient from '../../api/UserClient';
import { profileIdSelector } from '../../selectors/user';
import { messagesShow } from '../../redux/messages/actions';
import { subscribeToAPlanSuccess, subscribeToAPlanFailure } from '../../redux/user/actions';

export default function* subscribeToAPlan({ payload }) {
    const profileId = yield select(profileIdSelector);
    const planId = payload;
    const data = {
        planId,
        profileId,
    };
    try {
        yield call(UserClient.subscribeToAPlan, data);
        yield put(subscribeToAPlanSuccess());
        yield put(messagesShow('subscriptionsSuccess', 'success'));
    } catch (e) {
        yield put(subscribeToAPlanFailure());
        if (e && e.code && e.code === 409) {
            yield put(messagesShow('subscriptionsAlreadySubscribed', 'error'));
        } else {
            yield put(messagesShow('subscriptionsErrorMessage', 'error'));
        }
    }
}
