import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import setDisplayName from 'recompose/setDisplayName';

const { Provider, Consumer } = createContext({});

const keys = {
    pages: {
        faq: 'pages.faq',
        contact: 'pages.contact',
        register: 'pages.register',
        connect: 'pages.connect',
        account: 'pages.account',
        billing: 'pages.billing',
        credits: 'pages.credits',
        packages: 'pages.packages',
        logout: 'pages.logout',
        password_lost: 'pages.password_lost',
        reset_password: 'pages.reset_password',
        find_cars: 'pages.find_cars',
        myBookings: 'pages.myBookings',
        createBooking: 'pages.createBooking',
        invoicesReplay: 'pages.invoicesReplay',
        directAccess: {
            faq: 'pages.directAccess.faq',
            contact: 'pages.directAccess.contact',
            register: 'pages.directAccess.register',
            connect: 'pages.directAccess.connect',
            account: 'pages.directAccess.account',
            billing: 'pages.directAccess.billing',
            credits: 'pages.directAccess.credits',
            packages: 'pages.directAccess.packages',
            logout: 'pages.directAccess.logout',
            password_lost: 'pages.directAccess.password_lost',
            reset_password: 'pages.directAccess.reset_password',
            find_cars: 'pages.directAccess.find_cars',
            myBookings: 'pages.directAccess.myBookings',
            createBooking: 'pages.directAccess.createBooking',
            invoicesReplay: 'pages.directAccess.invoicesReplay',
            stationInformation: 'pages.directAccess.stationInformation',
            fuelCardDetails: 'pages.directAccess.fuelCardDetails',
        },
    },
    ui: {
        paymentDetailsBox: 'ui.payment_details_box',
        documents: 'ui.documents',
        modifyPhoneNumber: 'ui.modifyPhoneNumber',
        hideDownloadInvoiceButton: 'ui.hideDownloadInvoiceButton',
        reviewStatus: 'ui.reviewStatus',
        hideEditPersonalDetails: 'ui.hideEditPersonalDetails',
        country: 'ui.country',
        region: 'ui.region',
        displayEditDocument: 'ui.displayEditDocument',
        redirectToRegistrationIfIncomplete: 'ui.redirectToRegistrationIfIncomplete',
        displayPackageTaxesInformation: 'ui.displayPackageTaxesInformation',
        displayRequestAccountDeletion: 'ui.displayRequestAccountDeletion',
        showMarketingConsent: 'ui.showMarketingConsent',
        showSurveyConsent: 'ui.showSurveyConsent',
        showDataSharingConsent: 'ui.showDataSharingConsent',
        showProfilingConsent: 'ui.showProfilingConsent',
        showDataPrivacyConsent: 'ui.showDataPrivacyConsent',
        promocodesPerFleet: 'ui.promocodesPerFleet',
        hideSelectedServicesInProfile: 'ui.hideSelectedServicesInProfile',
        showMobilityPlans: 'ui.showMobilityPlans',
    },
};

const get = (value, path) =>
    String(path)
        .split('.')
        .reduce((acc, v) => {
            try {
                acc = acc[v];
            } catch (e) {
                return undefined;
            }
            return acc;
        }, value);

const isAllowed = acl => key => get(acl, key) || false;
const isDefined = acl => key => get(acl, key) !== undefined;
// check if ACL is defined and allowed. If not defined fallback to default value.
const isDefinedAndAllowed = acl => (key, defaultValue = false) =>
    get(acl, key) !== undefined ? get(acl, key) : defaultValue;

export const AclProvider = ({ acl, children }) => <Provider value={acl}>{children}</Provider>;

AclProvider.propTypes = {
    acl: PropTypes.shape({}).isRequired,
    children: PropTypes.shape({}).isRequired,
};

export const withAcl = Component =>
    setDisplayName('withAcl')(props => (
        <Consumer>
            {acl => (
                <Component
                    {...props}
                    acl={{
                        keys,
                        isAllowed: isAllowed(acl),
                        isDefined: isDefined(acl),
                        isDefinedAndAllowed: isDefinedAndAllowed(acl),
                    }}
                />
            )}
        </Consumer>
    ));
