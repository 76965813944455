import { CLEAR, BILLING_LIST_REQUEST, BILLING_LIST_SUCCESSFUL, BILLING_LIST_FAILED, BILLING_SET_FILTER } from './types';

export const clear = () => ({ type: CLEAR });
export const billingListRequest = () => ({ type: BILLING_LIST_REQUEST });
export const billingListSuccessful = datas => ({ type: BILLING_LIST_SUCCESSFUL, payload: datas });
export const billingListFailed = () => ({ type: BILLING_LIST_FAILED });
export const billingSetFilter = (year, month, profile) => ({
    type: BILLING_SET_FILTER,
    payload: { year, month, profile },
});
