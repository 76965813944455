import {
    CLEAR,
    USER_GET_REQUEST,
    USER_GET_SUCCESSFUL,
    USER_GET_PROFILE_SERVICES_WITH_STATUS,
    USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL,
    AGREE_TO_TERMS_OF_USE_REQUEST,
    USER_GET_FAILED,
    USER_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_REQUEST,
    USER_UPDATE_SUCCESSFUL,
    USER_UPDATE_FAILED,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESSFUL,
    USER_CHANGE_PASSWORD_FAILED,
    USER_SEND_LOST_PASSWORD_REQUEST,
    USER_SEND_LOST_PASSWORD_SUCCESSFUL,
    USER_SEND_LOST_PASSWORD_FAILED,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESSFUL,
    USER_RESET_PASSWORD_FAILED,
    USER_UPLOAD_SELECTED_USER_DOCUMENT_REQUEST,
    USER_UPDATE_DOCUMENTS_FILES,
    USER_SAVE_DOCUMENTS_FORM,
    USER_SET_PROFILE_TAB,
    USER_PROFILE_UPDATE_SUCCESSFUL,
    USER_PROFILE_UPDATE_FAILED,
    USER_FETCH_ALL_PLANS_REQUEST,
    USER_FETCH_ALL_PLANS_SUCCESSFUL,
    USER_SUBSCRIBE_TO_A_PLAN_REQUEST,
    USER_SUBSCRIBE_TO_A_PLAN_SUCCESS,
    USER_SUBSCRIBE_TO_A_PLAN_FAILURE,
    USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST,
    USER_UNSUBSCRIBE_FROM_A_PLAN_SUCCESS,
    USER_UNSUBSCRIBE_FROM_A_PLAN_FAILURE,
    USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS,
    USER_UPDATE_METHOD_OF_PAYMENT_FAILURE,
    USER_GET_PAYMENT_DETAILS,
    USER_GET_PAYMENT_DETAILS_SUCCESS,
    USER_GET_PAYMENT_DETAILS_FAILURE,
    USER_REQUEST_ACCOUNT_DELETION,
    USER_GET_PUBKEY_INTENT,
} from './types';

export const clear = () => ({ type: CLEAR });

// user (get & update)
export const userGetRequest = () => ({ type: USER_GET_REQUEST });
export const userGetSuccessful = data => ({
    type: USER_GET_SUCCESSFUL,
    payload: data,
});
export const userGetProfileServicesWithStatuses = () => ({
    type: USER_GET_PROFILE_SERVICES_WITH_STATUS,
});

export const userGetProfileServicesWithStatusesSuccessful = data => ({
    type: USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL,
    payload: data,
});

export const userGetFailed = () => ({ type: USER_GET_FAILED });
export const userUpdateRequest = data => ({
    type: USER_UPDATE_REQUEST,
    payload: data,
});
export const userUpdateSuccessful = () => ({ type: USER_UPDATE_SUCCESSFUL });
export const userUpdateFailed = () => ({ type: USER_UPDATE_FAILED });

// profile (update)
export const userProfileUpdateRequest = data => ({
    type: USER_PROFILE_UPDATE_REQUEST,
    payload: data,
});
export const userProfileUpdateSuccessful = () => ({
    type: USER_PROFILE_UPDATE_SUCCESSFUL,
});
export const userProfileUpdateFailed = () => ({
    type: USER_PROFILE_UPDATE_FAILED,
});

// password
export const userChangePasswordRequest = (username, currentPassword, newPassword) => ({
    type: USER_CHANGE_PASSWORD_REQUEST,
    payload: { username, currentPassword, newPassword },
});
export const userChangePasswordSuccessful = () => ({
    type: USER_CHANGE_PASSWORD_SUCCESSFUL,
});
export const userChangePasswordFailed = () => ({
    type: USER_CHANGE_PASSWORD_FAILED,
});
export const userSendLostPasswordRequest = email => ({
    type: USER_SEND_LOST_PASSWORD_REQUEST,
    payload: email,
});
export const userSendLostPasswordSuccessful = () => ({
    type: USER_SEND_LOST_PASSWORD_SUCCESSFUL,
});
export const userSendLostPasswordFailed = () => ({
    type: USER_SEND_LOST_PASSWORD_FAILED,
});
export const userResetPasswordRequest = (token, password) => ({
    type: USER_RESET_PASSWORD_REQUEST,
    payload: { token, password },
});
export const userResetPasswordSuccessful = () => ({
    type: USER_RESET_PASSWORD_SUCCESSFUL,
});
export const userResetPasswordFailed = () => ({
    type: USER_RESET_PASSWORD_FAILED,
});

export const userUploadDocumentAttachementAction = payload => ({
    type: USER_UPLOAD_SELECTED_USER_DOCUMENT_REQUEST,
    payload,
});
export const userUpdateDocumentFiles = payload => ({
    type: USER_UPDATE_DOCUMENTS_FILES,
    payload,
});

export const userSaveDocumentFormAction = payload => ({
    type: USER_SAVE_DOCUMENTS_FORM,
    payload,
});

export const userChangeProfileTab = payload => ({
    type: USER_SET_PROFILE_TAB,
    payload,
});

export const agreeToTermsOfUseRequest = () => ({
    type: AGREE_TO_TERMS_OF_USE_REQUEST,
});

export const fetchAllPlans = () => ({ type: USER_FETCH_ALL_PLANS_REQUEST });
export const setAllPlans = payload => ({ type: USER_FETCH_ALL_PLANS_SUCCESSFUL, payload });
export const subscribeToAPlan = payload => ({ type: USER_SUBSCRIBE_TO_A_PLAN_REQUEST, payload });
export const subscribeToAPlanSuccess = () => ({ type: USER_SUBSCRIBE_TO_A_PLAN_SUCCESS });
export const subscribeToAPlanFailure = () => ({ type: USER_SUBSCRIBE_TO_A_PLAN_FAILURE });
export const unsubscribeFromAPlan = () => ({ type: USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST });
export const unsubscribeFromAPlanSuccess = () => ({ type: USER_UNSUBSCRIBE_FROM_A_PLAN_SUCCESS });
export const unsubscribeFromAPlanFailure = () => ({ type: USER_UNSUBSCRIBE_FROM_A_PLAN_FAILURE });
export const updateMethodOfPaymentSuccess = payload => ({ type: USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS, payload });
export const updateMethodOfPaymentFailure = () => ({ type: USER_UPDATE_METHOD_OF_PAYMENT_FAILURE });
export const getPaymentDetails = () => ({ type: USER_GET_PAYMENT_DETAILS });
export const getPaymentDetailsSuccess = payload => ({ type: USER_GET_PAYMENT_DETAILS_SUCCESS, payload });
export const getPaymentDetailsFailure = () => ({ type: USER_GET_PAYMENT_DETAILS_FAILURE });
export const requestAccountDeletionAction = () => ({ type: USER_REQUEST_ACCOUNT_DELETION });
export const getPubKeyIntent = () => ({ type: USER_GET_PUBKEY_INTENT });
export const getPubKeyIntentSuccess = payload => ({ type: USER_GET_PUBKEY_INTENT, payload });
