import {
    PACKAGES_REQUEST_FAILED,
    PACKAGES_REQUEST_SUCCESSFUL,
    PACKAGE_PURCHASE_FAILED,
    PACKAGE_PURCHASE_SUCCESSFUL,
    RESET_DIALOG_STEP,
    SELECT_PACKAGE,
    START_PACKAGES_REQUEST,
    START_PURCHASE_REQUEST,
} from './types';

const initState = {
    fetching: false, // When we request the package list and we're waiting the server response
    confirmingPurchase: false, // When we request to buy a package and we're waiting the server response
    alreadyPurchased: false, // Display or not the informational box on top of the package list
    purchaseDialogStep: 1, // Steps in the dialog -> 1: Initial | 2: Success | 3: Fail
    selectedPackage: null, // Which package we have selected
    list: [], // The package list
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case START_PACKAGES_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case PACKAGES_REQUEST_SUCCESSFUL: {
            let packagesList = (action.payload && action.payload.packages) || [];

            // Sort by ascending price
            packagesList.sort((a, b) => {
                if (a.price === b.price) {
                    return 0;
                }
                return a.price - b.price;
            });
            const currency = (action.payload && action.payload.currency) || '';

            packagesList = packagesList.map(item => ({
                id: item.id,
                name: item.name,
                price: item.price,
                systemCreditsAvailable: item.systemCreditsAvailable,
                validityDays: item.validityDays || 0,
                savePercentage: Math.round(
                    ((item.systemCreditsAvailable - item.price) / item.systemCreditsAvailable) * 100
                ),
                currency,
                taxName: item.taxName,
                taxPercentage: Math.round(item.taxRate * 100),
                taxIncluded: item.taxIncluded,
            }));

            return {
                ...state,
                fetching: false,
                list: packagesList,
            };
        }
        case PACKAGES_REQUEST_FAILED:
            return {
                ...state,
                fetching: false,
            };

        case SELECT_PACKAGE: {
            if (action.payload && action.payload.item) {
                return {
                    ...state,
                    selectedPackage: {
                        id: action.payload.item.id,
                        name: action.payload.item.name,
                    },
                };
            }

            return state;
        }

        case START_PURCHASE_REQUEST: {
            return {
                ...state,
                confirmingPurchase: true,
            };
        }

        case PACKAGE_PURCHASE_SUCCESSFUL: {
            return {
                ...state,
                confirmingPurchase: false,
                alreadyPurchased: true,
                purchaseDialogStep: 2,
            };
        }

        case PACKAGE_PURCHASE_FAILED: {
            return {
                ...state,
                confirmingPurchase: false,
                purchaseDialogStep: 3,
            };
        }

        case RESET_DIALOG_STEP: {
            return {
                ...state,
                purchaseDialogStep: 1,
            };
        }

        default:
            return state;
    }
};

export default reducer;
