import {
    CLEAR,
    USER_GET_REQUEST,
    USER_GET_SUCCESSFUL,
    USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL,
    USER_GET_FAILED,
    USER_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_REQUEST,
    USER_UPDATE_SUCCESSFUL,
    USER_UPDATE_FAILED,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESSFUL,
    USER_CHANGE_PASSWORD_FAILED,
    USER_SEND_LOST_PASSWORD_REQUEST,
    USER_SEND_LOST_PASSWORD_SUCCESSFUL,
    USER_SEND_LOST_PASSWORD_FAILED,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESSFUL,
    USER_RESET_PASSWORD_FAILED,
    USER_UPLOAD_UPLOADING,
    USER_UPDATE_DOCUMENTS_FILES,
    USER_SAVE_DOCUMENTS_FORM_REQUEST,
    USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED,
    USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS,
    USER_SET_PROFILE_TAB,
    USER_PROFILE_UPDATE_SUCCESSFUL,
    USER_PROFILE_UPDATE_FAILED,
    USER_FETCH_ALL_PLANS_SUCCESSFUL,
    USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS,
    USER_UPDATE_METHOD_OF_PAYMENT_FAILURE,
    USER_SUBSCRIBE_TO_A_PLAN_REQUEST,
    USER_SUBSCRIBE_TO_A_PLAN_SUCCESS,
    USER_SUBSCRIBE_TO_A_PLAN_FAILURE,
    USER_GET_PAYMENT_DETAILS,
    USER_GET_PAYMENT_DETAILS_SUCCESS,
    USER_GET_PAYMENT_DETAILS_FAILURE,
    USER_SET_FAILED_INVOICES_PROCESSING,
} from './types';

const initState = {
    fetching: false,
    me: {
        address: {},
    },
    paymentDetails: null,
    paymentDetailsBusiness: null,
    updating: false,
    resetPasswordSuccess: false,
    documents: {},
    documentTypes: [],
    documentByService: null,
    services: [],
    subscriptions: [],
    allSubscriptionPlans: [],
    profileTab: 0,
    defaultPricing: {
        drivingPricePerMin: null,
        stopOverPricePerMin: null,
    },
    pubKey: undefined,
    updatePaymentMethodSuccess: null,
    userProfilesWithServices: [],
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR:
            return {
                ...initState,
            };

        case USER_GET_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case USER_GET_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                me: action.payload.user,
                paymentDetails: action.payload.paymentDetails,
                paymentDetailsBusiness: action.payload.paymentDetailsBusiness,
                documents: action.payload.documents || {},
                documentTypes: action.payload.documentTypes || [],
                documentByService: action.payload.documentByService || null,
                services: action.payload.services || [],
                subscriptions: action.payload.subscriptions || [],
                defaultPricing: action.payload.defaultPricing || initState.defaultPricing,
                pubKey: action.payload.pubKey,
            };
        case USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL:
            return {
                ...state,
                userProfilesWithServices: action.payload.userProfilesWithServices,
            };

        case USER_GET_FAILED:
            return {
                ...state,
                fetching: false,
            };

        case USER_SET_PROFILE_TAB:
            return {
                ...state,
                profileTab: action.payload,
            };

        case USER_UPDATE_REQUEST:
        case USER_PROFILE_UPDATE_REQUEST:
        case USER_CHANGE_PASSWORD_REQUEST:
        case USER_SEND_LOST_PASSWORD_REQUEST:
        case USER_RESET_PASSWORD_REQUEST:
        case USER_SAVE_DOCUMENTS_FORM_REQUEST:
            return {
                ...state,
                updating: true,
                resetPasswordSuccess: false,
            };
        case USER_RESET_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                updating: false,
                resetPasswordSuccess: true,
            };

        case USER_UPDATE_SUCCESSFUL:
        case USER_UPDATE_FAILED:
        case USER_PROFILE_UPDATE_SUCCESSFUL:
        case USER_PROFILE_UPDATE_FAILED:
        case USER_CHANGE_PASSWORD_SUCCESSFUL:
        case USER_CHANGE_PASSWORD_FAILED:
        case USER_SEND_LOST_PASSWORD_SUCCESSFUL:
        case USER_SEND_LOST_PASSWORD_FAILED:
        case USER_RESET_PASSWORD_FAILED:
        case USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED:
        case USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                updating: false,
            };
        case USER_UPLOAD_UPLOADING:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action.payload.documentType]: {
                        ...state.documents[action.payload.documentType],
                        uploadQueue: [
                            ...(
                                (state.documents[action.payload.documentType] &&
                                    state.documents[action.payload.documentType].uploadQueue) ||
                                []
                            ).filter(u => u.name !== action.payload.uploadQueue.name),
                            action.payload.uploadQueue,
                        ],
                    },
                },
            };
        case USER_UPDATE_DOCUMENTS_FILES:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action.payload.documentType]: {
                        ...state.documents[action.payload.documentType],
                        files: [...(state.documents[action.payload.documentType].files || []), ...action.payload.files],
                    },
                },
            };
        case USER_FETCH_ALL_PLANS_SUCCESSFUL:
            return {
                ...state,
                allSubscriptionPlans: action.payload,
            };

        case USER_SUBSCRIBE_TO_A_PLAN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case USER_SUBSCRIBE_TO_A_PLAN_SUCCESS:
            return {
                ...state,
                fetching: false,
            };

        case USER_SUBSCRIBE_TO_A_PLAN_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS:
            return {
                ...state,
                updatePaymentMethodSuccess: action.payload,
            };

        case USER_UPDATE_METHOD_OF_PAYMENT_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case USER_GET_PAYMENT_DETAILS:
            return {
                ...state,
                fetching: true,
            };

        case USER_GET_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                paymentDetails: action.payload,
                fetching: false,
            };

        case USER_GET_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case USER_SET_FAILED_INVOICES_PROCESSING:
            return {
                ...state,
                me: {
                    ...state.me,
                    profile: {
                        ...state.me.profile,
                        showPayableInvoicesButton: false,
                    },
                },
            };
        default:
            return state;
    }
};

export default reducer;
