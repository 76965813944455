import { call, put } from 'redux-saga/effects';

import BookingClient from '../../api/BookingClient';
import { fetchPaidSBRequestsFailed, fetchPaidSBRequestsSuccessful } from '../../redux/bookings/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*() {
    try {
        const bookingsResult = yield call(BookingClient.getPaidBookings);
        const isPaidSBRequests = bookingsResult.some(booking => booking.paymentReceipts !== undefined);
        yield put(fetchPaidSBRequestsSuccessful({ data: bookingsResult, isPaidSBRequests }));
    } catch (error) {
        yield put(fetchPaidSBRequestsFailed());
        yield put(messagesShow('internalServerError', 'error'));
    }
}
