import { all, takeLatest } from 'redux-saga/effects';

import { GET_INVOICES_REPLAY_DATA, PAY_FAILED_INVOICES, PAY_INVOICES } from '../../redux/invoicesReplay/types';

import { getInvoicesReplayDataSaga } from './get';
import { payInvoicesSaga, payFailedInvoicesSaga } from './payInvoices';

export default function*() {
    yield all([
        takeLatest(GET_INVOICES_REPLAY_DATA, getInvoicesReplayDataSaga),
        takeLatest(PAY_INVOICES, payInvoicesSaga),
        takeLatest(PAY_FAILED_INVOICES, payFailedInvoicesSaga),
    ]);
}
