import { select, call, put } from 'redux-saga/effects';

import { entityIdSelector } from '../../selectors/user';
import UserClient from '../../api/UserClient';
import { setSessionStorageItem } from '../../helpers/LocalStorage';

import { setAdyen3dsAction, setAdyen3dsRequired } from '../../redux/app/actions';
import { messagesShow } from '../../redux/messages/actions';
import { getPaymentDetails } from '../../redux/user/actions';

export default function*({ payload, toggleAdyenModal }) {
    const entityId = yield select(entityIdSelector);
    const { paymentMethod, browserInfo, origin } = payload;

    const updatedPayload = {
        paymentMethodType: 'CREDIT_CARD',
        returnURL: `${window.location.origin}/payment-result`,
        paymentMethod: {
            card: {
                name: paymentMethod.holderName,
                number: paymentMethod.encryptedCardNumber,
                expiryMonth: paymentMethod.encryptedExpiryMonth,
                expiryYear: paymentMethod.encryptedExpiryYear,
                cvc: paymentMethod.encryptedSecurityCode,
            },
            browserInfo: {
                origin,
                userAgent: browserInfo.userAgent,
                colorDepth: browserInfo.colorDepth,
                javaEnabled: browserInfo.javaEnabled,
                language: browserInfo.language,
                screenHeight: browserInfo.screenHeight,
                screenWidth: browserInfo.screenWidth,
                timeZoneOffset: browserInfo.timeZoneOffset,
                acceptHeader: browserInfo.acceptHeader,
                javaScriptEnabled: browserInfo.javaScriptEnabled,
            },
        },
    };

    try {
        const { nextAction, id, status } = yield call(UserClient.onSetupIntent, { entityId, ...updatedPayload });
        if (nextAction) {
            setSessionStorageItem('setupIntentId', id);
            setSessionStorageItem('entityId', entityId);
            yield put(setAdyen3dsAction({ nextAction }));
        } else {
            if (status === 'SUCCEEDED') {
                yield call(UserClient.synchronizeSetupIntent, entityId, id);
                yield put(messagesShow('PaymentSuccess', 'success'));
                yield put(getPaymentDetails());
            } else {
                yield put(messagesShow('PaymentError', 'error'));
            }
            yield put(setAdyen3dsRequired({ is3dsRequired: false }));
            toggleAdyenModal();
        }
    } catch (error) {
        console.error('PaymentError', error);
        yield put(setAdyen3dsRequired({ is3dsRequired: false }));
        yield put(messagesShow('PaymentError', 'error'));
        toggleAdyenModal();
    }
}
