import axios from 'axios';
import adyenApiConf from '../resources/adyen.json';

export default async host => {
    const {
        location: { hostname },
    } = window;

    const dir = host || hostname;

    try {
        const config = await axios.get(`/fe-api/anonymous/fo/${dir}/conf`).then(reponse => reponse.data);
        return {
            ...config,
            adyenApi: adyenApiConf,
        };
    } catch (error) {
        throw new Error(`Configuration not found for : ${dir}`);
    }
};
