// Package list
export const PACKAGES_REQUEST = '@packages/PACKAGES_REQUEST';
export const START_PACKAGES_REQUEST = '@packages/START_PACKAGES_REQUEST';
export const PACKAGES_REQUEST_SUCCESSFUL = '@packages/PACKAGES_REQUEST_SUCCESSFUL';
export const PACKAGES_REQUEST_FAILED = '@packages/PACKAGES_REQUEST_FAILED';

// Purchase dialog
export const PACKAGE_PURCHASE_REQUEST = '@packages/PACKAGE_PURCHASE_REQUEST';
export const START_PURCHASE_REQUEST = '@packages/START_PURCHASE_REQUEST';
export const PACKAGE_PURCHASE_SUCCESSFUL = '@packages/PACKAGE_PURCHASE_SUCCESSFUL';
export const PACKAGE_PURCHASE_FAILED = '@packages/PACKAGE_PURCHASE_FAILED';

// Various
export const SELECT_PACKAGE = '@packages/SELECT_PACKAGE';
export const RESET_DIALOG_STEP = '@packages/RESET_DIALOG_STEP';
