import { call, put, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import {
    checkMultiFleetAvailabilitySuccess,
    freeminutesAddFailed,
    checkMultiFleetAvailabilityFailure,
    freeminutesAddRequest,
} from '../../redux/freeminutes/actions';
import { messagesShow } from '../../redux/messages/actions';
import { listSelector } from '../../selectors/freeminutes';

export const checkMultiFleetAvailabilitySaga = function* checkMultiFleetAvailability({ payload }) {
    try {
        const data = yield call(UserClient.getPromoCodeFleetsAvailability, payload);
        const acceptedCities = data.map(city => ({ id: city.id, name: city.name }));
        if (acceptedCities.length === 0) {
            // The reference doesn't exist. Remove loading state
            yield put(checkMultiFleetAvailabilityFailure());
            throw new Error();
        } else if (acceptedCities.length === 1) {
            // There is only one city. Redeem promo code.
            yield put(freeminutesAddRequest({ promoCode: payload, selectedCity: acceptedCities[0].id }));
        } else {
            const list = yield select(listSelector);
            const isReferenceRedeemed = list.some(credit => credit.promoCodeReference === payload);
            if (isReferenceRedeemed) {
                // The user already redeemed this promo code. No need to go further. We display an error message.
                throw new Error('promocodeConflict');
            }
            // Multiple cities. Display city list.
            yield put(checkMultiFleetAvailabilitySuccess(acceptedCities));
        }
    } catch (error) {
        if (error.message === 'promocodeConflict') {
            yield put(messagesShow('promocodeConflictErrorMessage', 'error'));
            yield put(freeminutesAddFailed());
        } else {
            yield put(messagesShow('PromoCodeRedeemFailedMessage', 'error'));
            yield put(freeminutesAddFailed());
        }
    }
};
