import { call, put, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import {
    getInvoicesReplayDataSuccess,
    getInvoicesReplayDataFailure,
    setIsPaymentProcessing,
} from '../../redux/invoicesReplay/actions';
import { messagesShow } from '../../redux/messages/actions';
import { isPaymentProcessingSelector } from '../../selectors/invoicesReplay';

export const getInvoicesReplayDataSaga = function*() {
    try {
        const invoicesReplayList = yield call(UserClient.getInvoicesReplayList);

        const data = invoicesReplayList.stripe || {};
        yield put(getInvoicesReplayDataSuccess(data));

        const isPaymentProcessing = yield select(isPaymentProcessingSelector);
        if (isPaymentProcessing) {
            yield put(setIsPaymentProcessing(false));
        }
    } catch (error) {
        yield put(getInvoicesReplayDataFailure());
        const errorMsg = error.message && typeof error.message === 'string' ? error.message : 'internalServerError';
        yield put(messagesShow(errorMsg, 'error'));
    }
};
