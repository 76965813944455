import {
    CLEAR,
    FREE_MINUTES_ADD_REQUEST,
    FREE_MINUTES_ADD_FAILED,
    FREE_MINUTES_LIST_REQUEST,
    FREE_MINUTES_LIST_SUCCESSFUL,
    FREE_MINUTES_LIST_FAILED,
    CHECK_MULTI_FLEET_AVAILABILITY,
    CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS,
    CHECK_MULTI_FLEET_AVAILABILITY_FAILURE,
    FREE_MINUTES_CLEAR,
} from './types';

const initState = {
    adding: false,
    fetching: false,
    acceptedCities: [],
    list: null,
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR:
            return {
                ...initState,
            };

        case FREE_MINUTES_ADD_REQUEST:
            return {
                ...state,
                adding: true,
            };

        case FREE_MINUTES_ADD_FAILED:
            return {
                ...state,
                adding: false,
            };

        case FREE_MINUTES_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case FREE_MINUTES_LIST_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                list: action.payload,
            };

        case FREE_MINUTES_LIST_FAILED:
            return {
                ...state,
                fetching: false,
            };

        case CHECK_MULTI_FLEET_AVAILABILITY:
            return {
                ...state,
                adding: true,
            };

        case CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS:
            return {
                ...state,
                acceptedCities: action.payload,
            };

        case CHECK_MULTI_FLEET_AVAILABILITY_FAILURE:
            return {
                ...state,
                adding: false,
            };

        case FREE_MINUTES_CLEAR:
            return {
                ...state,
                adding: initState.adding,
                fetching: initState.fetching,
                acceptedCities: initState.acceptedCities,
            };

        default:
            return state;
    }
};

export default reducer;
