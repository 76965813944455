import moment from 'moment';
import OpenIDClient from './OpenIDClient'; // { anonymousOpenID, signUpOpenID, authenticatedOpenID }
import ApiClient from './ApiClient';

const UserClientDateFormat = 'YYYY-MM-DD';
class UserClient extends ApiClient {
    init = apiConf => {
        this.apiConf = apiConf;
    };

    fetchToken = (username, password, recaptchaToken, recaptchaSiteKey) =>
        new Promise((resolve, reject) =>
            OpenIDClient.authenticatedOpenID
                .fetchToken(username, password, recaptchaToken, recaptchaSiteKey)
                .then(resolve)
                .catch(e => reject(e.response))
        );

    fetchTokenAnonymous = () =>
        new Promise((resolve, reject) =>
            OpenIDClient.anonymousOpenID
                .fetchToken()
                .then(resolve)
                .catch(e => reject(e.response))
        );

    fetchToken2FA = payload =>
        new Promise((resolve, reject) =>
            OpenIDClient.authenticatedOpenID
                .fetchToken2FA(payload)
                .then(resolve)
                .catch(e => reject(e.response))
        );

    refreshToken = () =>
        new Promise((resolve, reject) =>
            OpenIDClient.authenticatedOpenID
                .fetchRefreshToken()
                .then(resolve)
                .catch(this.customReject(reject))
        );

    getUser = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user`);

    getUserProfile = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/profile`);

    getUserServices = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/services`);

    getUserDocuments = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/documents`);

    getPaymentDetails = entityId =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/paymentMethodDetails/${entityId}`);

    getCities = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}cities`);

    updateUser = user => this.put(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user`, user);

    updateProfile = profile =>
        this.put(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/profiles/${profile.id}`, profile);

    createUser = ({ login, email, password, locale }) =>
        this.post(
            OpenIDClient.signUpOpenID,
            `${this.apiConf.baseEndpoint}users`,
            { login, email, password, locale },
            resolve => () => resolve(),
            reject => error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 422: // Login or email already exist (parse the body)
                            reject(this.buildReply(error.response.status, 'User already exists!'));
                            break;
                        default:
                            reject(this.buildReply(error.response.status, 'Some error occurred'));
                            break;
                    }
                } else {
                    reject(this.buildReply(error.response.status, 'Some unrecognised error occurred'));
                }
            }
        );

    createDocument = ({
        documentType,
        documentNumber,
        expirationDate,
        issuingCountry,
        issuingDate,
        issuingOffice,
        dlClass,
    }) => {
        const apiUrl = `${this.apiConf.baseEndpoint}user/documents`;
        return this.post(OpenIDClient.authenticatedOpenID, apiUrl, {
            documentType,
            documentNumber,
            expirationDate,
            issuingCountry,
            issuingDate,
            issuingOffice,
            dlClass,
        });
    };

    uploadDocument = (uploadEndpoint, file) => this.post(OpenIDClient.authenticatedOpenID, uploadEndpoint.url, file);

    changePassword = password =>
        this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}changePassword`,
            { password },
            resolve => () => resolve(this.buildReply(200, 'ok'))
        );

    sendLostPasswordEmail = login =>
        this.post(
            OpenIDClient.anonymousOpenID,
            `${this.apiConf.baseEndpoint}sendLostPasswordEmail`,
            { login },
            resolve => () => resolve(this.buildReply(200, 'ok'))
        );

    resetLostPassword = (token, password) =>
        this.post(
            OpenIDClient.anonymousOpenID,
            `${this.apiConf.baseEndpoint}resetLostPassword`,
            { token, password },
            resolve => () => resolve(this.buildReply(200, 'ok'))
        );

    fetchMyTrips = (
        profileType,
        profileId,
        startDate = moment()
            .subtract(10, 'years')
            .utc()
            .format(UserClientDateFormat),
        endDate = moment()
            .utc()
            .format(UserClientDateFormat)
    ) => {
        let queryParam;
        if (profileId && profileType)
            queryParam = `startDate=${startDate}&endDate=${endDate}&profileType=${profileType}&profileId=${profileId}`;
        else if (profileType) queryParam = `startDate=${startDate}&endDate=${endDate}&profileType=${profileType}`;
        else queryParam = `startDate=${startDate}&endDate=${endDate}`;
        return this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}invoices/tripDetails?${queryParam}`
        );
    };

    fetchMyProducts = (
        startDate = moment()
            .utc()
            .subtract(10, 'years')
            .format(UserClientDateFormat),
        endDate = moment()
            .utc()
            .format(UserClientDateFormat)
    ) =>
        this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}invoices/productDetails?startDate=${startDate}&endDate=${endDate}`
        );

    fetchMyOldInvoices = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}listMonthsWithOldInvoices`);

    fetchMyPromoCodes = entityId =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}wallet/entity/${entityId}`);

    agreeToCityTerms = cityId =>
        this.post(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}termsOfUse`, cityId);

    getSystemCreditsPackages = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}systemCreditsPackages`);

    onPackagePurchase = id =>
        this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}user/profile/purchaseSystemCreditsPackages/${id}`
        );

    getPromoCodeFleetsAvailability = promoCode =>
        this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}user/promocodes/reference/${promoCode}/cities`
        );

    redeemPromoCode = ({ promoCode, selectedCity }) =>
        this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}user/promocodes/reference/${promoCode}/redeem`,
            {
                cityId: selectedCity || null,
            }
        );

    urlInvoice = (token, invoiceId) =>
        `${this.apiConf.baseEndpoint}invoices/${invoiceId}/pdf?access_token=${token}&x-api-key=${this.apiConf.authenticatedAccess.apiKey}`;

    getOldInvoice = date =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}oldInvoicesUrl?date=${date}`);

    getPricing = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}pricing`);

    getPricingById = pricingId =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}pricing/${pricingId}`);

    getUserSubscriptions = profileId =>
        this.get(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}users/subscriptions?profileId=${profileId}`
        );

    fetchAllPlans = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}plans`);

    fetchPlanFees = () => this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}fees`);

    subscribeToAPlan = payload =>
        this.post(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}users/subscriptions`, payload);

    unsubscribeFromAPlan = ({ subscriptionId, profileId }) =>
        this.put(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}users/subscriptions/${subscriptionId}`,
            { profileId }
        );

    onPayFailedInvoices = () =>
        this.post(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/invoices/payAll`);

    onSetupIntent = ({ entityId, ...updatedPayload }) =>
        this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}user/entity/${entityId}/setup_intents`,
            { ...updatedPayload }
        );

    synchronizeSetupIntent = (entityId, setupIntentId, redirectResult) =>
        this.post(
            OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}user/entity/${entityId}/setup_intents/${setupIntentId}/synchronize`,
            { ...(redirectResult && { rawData: { redirectResult } }) }
        );

    getPublishableKey = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/mop/stripe/key`);

    getPublishableKeyForIntent = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/mop/key`);

    getInvoicesReplayList = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}paymentOptions`);

    onPayInvoices = payload =>
        this.post(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/invoices/replays`, payload);

    getPasswordStrength = () => {
        return this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/passwordStrength`);
    };

    requestAccountDeletionEndpoint = () =>
        this.post(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}user/privacy/inactiveaccount`);
}

export default new UserClient();
