import { call, put, select } from 'redux-saga/effects';

import { fetchModelAssetsFinished } from '../../redux/bookings/actions';
import BookingClient from '../../api/BookingClient';
import { isAuthenticateSelector } from '../../selectors/login';

export default function*(payload) {
    try {
        const modelId = payload.value;
        const isUserAuthenticated = yield select(isAuthenticateSelector);

        const response = yield call(BookingClient.getAssetsOfModel, modelId, isUserAuthenticated);

        let assets = null;
        if (response && Array.isArray(response.assets) && response.assets.length > 0) {
            assets = response.assets.map(asset => {
                if (asset.type === 'TEXT') {
                    return { ...asset, text: JSON.parse(asset.text) };
                }
                return asset;
            });
        }

        yield put(fetchModelAssetsFinished(assets));
    } catch (error) {
        console.error('Error in fetchModelAssets', error);
        yield put(fetchModelAssetsFinished());
    }
}
