import {
    ACTION_GET_VEHICLE_ONGOING_TRIP_PIN_CODE,
    ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_SUCCESS,
    ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_ERROR,
} from './types';

export const getVehicheOngoingTripPINCodeAction = payload => ({
    type: ACTION_GET_VEHICLE_ONGOING_TRIP_PIN_CODE,
    payload,
});

export const setVehicleOngoingTripPINCodeAction = payload => ({
    type: ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_SUCCESS,
    payload,
});

export const setVehicleOngoingTripPINCodeErrorAction = payload => ({
    type: ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_ERROR,
    payload,
});
