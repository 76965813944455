import { combineReducers } from 'redux';
import billing from './billing';
import freeminutes from './freeminutes';
import login from './login';
import messages from './messages';
import user from './user';
import invoicesReplay from './invoicesReplay';
import app from './app';
import packages from './packages';
import bookings from './bookings';
import stations from './stations';
import fuelCard from './fuelCard';

export default combineReducers({
    billing,
    freeminutes,
    packages,
    login,
    messages,
    user,
    invoicesReplay,
    bookings,
    app,
    stations,
    fuelCard,
});
