import OpenIDClient from './OpenIDClient';
import ApiClient from './ApiClient';

class VehicleClient extends ApiClient {
    init = apiConf => {
        this.apiConf = apiConf;
    };

    getVehicleOngoingTripPINCodeEndpoint = vehicleId =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}vehicles/${vehicleId}/pinCode`);
}

export default new VehicleClient();
