// source: https://github.com/holidaycheck/react-google-tag-manager/
import React from 'react';
import buildParts from './scriptHelper';

function GTMParts(args) {
    const parts = buildParts(args);

    function noScriptAsReact() {
        return <noscript dangerouslySetInnerHTML={{ __html: parts.iframe }} />;
    }

    function noScriptAsHTML() {
        return parts.iframe;
    }

    function scriptAsReact() {
        return <script dangerouslySetInnerHTML={{ __html: parts.script }} />;
    }

    function scriptAsText() {
        return parts.script;
    }

    return {
        noScriptAsReact,
        noScriptAsHTML,
        scriptAsReact,
        scriptAsText,
    };
}

export default GTMParts;
