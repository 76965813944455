import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { userChangePasswordSuccessful, userChangePasswordFailed } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload: { username, currentPassword, newPassword } }) {
    try {
        yield call(UserClient.fetchToken, username, currentPassword);
        yield call(UserClient.changePassword, newPassword);
        yield put(userChangePasswordSuccessful());
        yield put(messagesShow('resetPasswordSuccefull', 'success'));
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        }
        if (error.status === 401) {
            yield put(messagesShow('currentPasswordIncorrect', 'error'));
            yield put(userChangePasswordFailed());
        } else {
            yield put(userChangePasswordFailed());
            yield put(messagesShow('editNotOk', 'error'));
        }
    }
}
