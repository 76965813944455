import { call, put } from '@redux-saga/core/effects';
import UserClient from '../../api/UserClient';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import { packagePurchaseFailed, packagePurchaseSuccessful, startPurchaseRequest } from '../../redux/packages/actions';

export default function*({ payload }) {
    try {
        yield put(startPurchaseRequest());

        yield call(UserClient.onPackagePurchase, payload && payload.id);
        yield put(packagePurchaseSuccessful());
    } catch (err) {
        if (err.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(packagePurchaseFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
