import axios from 'axios';
import moment from 'moment';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/LocalStorage';

class BaseOpenIDClient {
    openIDConf = null;

    baseURL = null;

    isRefresh = false;

    accessToken = null;

    expiresIn = null;

    refreshToken = null;

    refreshExpiresIn = null;

    instanceAxios = null;

    isRefreshing = false;

    isBeforeRefreshing = false;

    refreshSubscribers = [];

    refreshBeforeSubscribers = [];

    constructor(openIDConf, baseURL, isRefresh = false) {
        this.openIDConf = openIDConf;
        this.isRefresh = isRefresh;
        this.baseURL = baseURL;

        this.instanceAxios = axios.create({
            timeout: 30000,
            headers: {
                'Cache-Control': 'no-cache',
                'X-API-Key': openIDConf.apiKey,
                'Content-Type': 'application/json',
            },
            withCredentials: false,
        });

        if (isRefresh) {
            const { refreshToken, refreshExpiresIn } = getLocalStorageItem('refreshToken') || {
                refreshToken: null,
                refreshExpiresIn: null,
            };
            this.refreshToken = refreshToken;
            this.refreshExpiresIn = refreshExpiresIn ? moment(refreshExpiresIn) : moment();
        }

        this.instanceAxios.interceptors.response.use(
            response => response,
            error => {
                const {
                    config,
                    response: { status },
                } = error;
                const originalRequest = config;

                if (status === 401 && config.url !== this.baseURL) {
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        this.fetchRefreshToken()
                            .then(() => {
                                this.refreshSubscribers.forEach(cb => cb(null));
                                this.refreshSubscribers = [];
                                this.isRefreshing = false;
                            })
                            .catch(errorRefresh => {
                                this.logout();
                                this.refreshSubscribers.forEach(cb => cb(errorRefresh));
                                this.refreshSubscribers = [];
                                this.isRefreshing = false;
                            });
                    }

                    const retryOrigReq = new Promise((resolve, reject) => {
                        this.refreshSubscribers.push(errorRefresh => {
                            if (errorRefresh) {
                                reject(errorRefresh);
                                return;
                            }

                            originalRequest.headers.Authorization = `Bearer ${this.accessToken}`;
                            resolve(this.instanceAxios.request(originalRequest));
                        });
                    });
                    return retryOrigReq;
                }
                return Promise.reject(error);
            }
        );
    }

    postData = (params, customUrl = null) =>
        this.instanceAxios
            .request({
                method: 'POST',
                url: customUrl || this.baseURL,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(
                ({
                    data: {
                        access_token: accessToken,
                        expires_in: expiresIn,
                        refresh_expires_in: refreshExpiresIn,
                        refresh_token: refreshToken,
                    },
                }) => {
                    this.accessToken = accessToken;
                    this.expiresIn = moment().add(expiresIn, 'seconds');

                    this.instanceAxios.defaults.headers.Authorization = `Bearer ${this.accessToken}`;
                    if (this.isRefresh) {
                        this.refreshToken = refreshToken;
                        this.refreshExpiresIn = moment().add(refreshExpiresIn, 'seconds');
                        setLocalStorageItem('refreshToken', {
                            refreshToken: this.refreshToken,
                            refreshExpiresIn: this.refreshExpiresIn,
                        });
                    } else {
                        this.refreshToken = null;
                        this.refreshExpiresIn = null;
                    }

                    return 'OK';
                }
            );

    fetchRefreshToken = () => {
        const params = new URLSearchParams();
        params.append('refresh_token', this.refreshToken);
        params.append('client_id', this.openIDConf.clientId);
        params.append('client_secret', this.openIDConf.clientSecret);
        params.append('securityOptions', 'SSL_OP_NO_SSLv3');
        params.append('grant_type', 'refresh_token');

        return this.postData(params);
    };

    isAuthenticate = () =>
        !!(
            (this.accessToken && this.expiresIn.isAfter(moment())) ||
            !this.isRefresh ||
            (this.refreshToken && this.refreshExpiresIn.isAfter(moment()))
        );

    logout = () => {
        this.accessToken = null;
        this.expiresIn = null;
        this.refreshToken = null;
        this.refreshExpiresIn = null;
        delete this.instanceAxios.defaults.headers.Authorization;
        setLocalStorageItem('refreshToken', null);
    };
}

class AnonymousOpenIDClient extends BaseOpenIDClient {
    constructor(openIDConf, baseURL, isRefresh = false) {
        super(openIDConf, baseURL, isRefresh);
    }

    fetchToken = () => {
        const params = new URLSearchParams();
        params.append('client_id', this.openIDConf.clientId);
        params.append('client_secret', this.openIDConf.clientSecret);
        params.append('securityOptions', 'SSL_OP_NO_SSLv3');
        params.append('grant_type', 'client_credentials');

        return this.postData(params);
    };

    fetchRefreshToken = () => this.fetchToken();
}

class AuthenticatedOpenIDClient extends BaseOpenIDClient {
    constructor(openIDConf, baseURL, isRefresh = false) {
        super(openIDConf, baseURL, isRefresh);
    }

    fetchToken = (username, password, recaptchaToken, recaptchaSiteKey) => {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        if (recaptchaSiteKey) {
            params.append('recaptcha_token', recaptchaToken);
            params.append('recaptcha_site_key', recaptchaSiteKey);
        }
        params.append('client_id', this.openIDConf.clientId);
        params.append('client_secret', this.openIDConf.clientSecret);
        params.append('securityOptions', 'SSL_OP_NO_SSLv3');
        params.append('grant_type', 'password');

        return this.postData(params);
    };

    fetchToken2FA = payload => {
        const { customUrl, pinCode, sessionId } = payload;
        const params = new URLSearchParams();
        params.append('code', pinCode);
        params.append('session_id', sessionId);

        return this.postData(params, customUrl);
    };
}

class OpenIDClient {
    anonymousOpenID = null;

    signUpOpenID = null;

    authenticatedOpenID = null;

    initOpenID = apiConf => {
        this.anonymousOpenID = new AnonymousOpenIDClient(apiConf.anonymousAccess, apiConf.authEndpoint);
        this.signUpOpenID = new AnonymousOpenIDClient(apiConf.signUp, apiConf.authEndpoint);
        this.authenticatedOpenID = new AuthenticatedOpenIDClient(
            apiConf.authenticatedAccess,
            apiConf.authEndpoint,
            true
        );
    };
}

const instance = new OpenIDClient();

export default instance;
