export const CLEAR = '@user/CLEAR';
export const USER_GET_REQUEST = '@user/USER_GET_REQUEST';
export const USER_GET_SUCCESSFUL = '@user/USER_GET_SUCCESSFUL';
export const USER_GET_PROFILE_SERVICES_WITH_STATUS = '@user/USER_GET_PROFILE_SERVICES_WITH_STATUS';
export const USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL =
    '@user/USER_GET_PROFILE_SERVICES_WITH_STATUS_SUCCESSFUL';

export const AGREE_TO_TERMS_OF_USE_REQUEST = '@bookings/AGREE_TO_TERMS_OF_USE_REQUEST';

export const USER_GET_FAILED = '@user/USER_GET_FAILED';
export const USER_UPDATE_REQUEST = '@user/USER_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_REQUEST = '@user/USER_PROFILE_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESSFUL = '@user/USER_UPDATE_SUCCESSFUL';
export const USER_UPDATE_FAILED = '@user/USER_UPDATE_FAILED';
export const USER_CHANGE_PASSWORD_REQUEST = '@user/USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESSFUL = '@user/USER_CHANGE_PASSWORD_SUCCESSFUL';
export const USER_CHANGE_PASSWORD_FAILED = '@user/USER_CHANGE_PASSWORD_FAILED';
export const USER_SEND_LOST_PASSWORD_REQUEST = '@user/USER_SEND_LOST_PASSWORD_REQUEST';
export const USER_SEND_LOST_PASSWORD_SUCCESSFUL = '@user/USER_SEND_LOST_PASSWORD_SUCCESSFUL';
export const USER_SEND_LOST_PASSWORD_FAILED = '@user/USER_SEND_LOST_PASSWORD_FAILED';
export const USER_RESET_PASSWORD_REQUEST = '@user/USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESSFUL = '@user/USER_RESET_PASSWORD_SUCCESSFUL';
export const USER_RESET_PASSWORD_FAILED = '@user/USER_RESET_PASSWORD_FAILED';
export const USER_UPLOAD_SELECTED_USER_DOCUMENT_REQUEST = '@user/USER_UPLOAD_SELECTED_USER_DOCUMENT_REQUEST';
export const USER_UPLOAD_UPLOADING = '@users/USER_UPLOAD_UPLOADING';
export const USER_UPDATE_DOCUMENTS_FILES = '@users/USER_UPDATE_DOCUMENTS_FILES';
export const USER_SAVE_DOCUMENTS_FORM = '@users/USER_SAVE_DOCUMENTS_FORM';
export const USER_SAVE_DOCUMENTS_FORM_REQUEST = '@users/USER_SAVE_DOCUMENTS_FORM_REQUEST';
export const USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED = '@users/USER_SAVE_DOCUMENTS_FORM_REQUEST_FAILED';
export const USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS = '@users/USER_SAVE_DOCUMENTS_FORM_REQUEST_SUCCESS';
export const USER_SET_PROFILE_TAB = '@users/USER_SET_PROFILE_TAB';
export const USER_PROFILE_UPDATE_SUCCESSFUL = '@users/USER_PROFILE_UPDATE_SUCCESSFUL';
export const USER_PROFILE_UPDATE_FAILED = '@users/USER_PROFILE_UPDATE_FAILED';
export const USER_FETCH_ALL_PLANS_REQUEST = '@users/USER_FETCH_ALL_PLANS_REQUEST';
export const USER_FETCH_ALL_PLANS_SUCCESSFUL = '@users/USER_FETCH_ALL_PLANS_SUCCESSFUL';
export const USER_SUBSCRIBE_TO_A_PLAN_REQUEST = '@users/USER_SUBSCRIBE_TO_A_PLAN_REQUEST';
export const USER_SUBSCRIBE_TO_A_PLAN_SUCCESS = '@users/USER_SUBSCRIBE_TO_A_PLAN_SUCCESS';
export const USER_SUBSCRIBE_TO_A_PLAN_FAILURE = '@users/USER_SUBSCRIBE_TO_A_PLAN_FAILURE';
export const USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST = '@users/USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST';
export const USER_UNSUBSCRIBE_FROM_A_PLAN_SUCCESS = '@users/USER_UNSUBSCRIBE_FROM_A_PLAN_SUCCESS';
export const USER_UNSUBSCRIBE_FROM_A_PLAN_FAILURE = '@users/USER_UNSUBSCRIBE_FROM_A_PLAN_FAILURE';
export const USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS = '@users/USER_UPDATE_METHOD_OF_PAYMENT_SUCCESS';
export const USER_UPDATE_METHOD_OF_PAYMENT_FAILURE = '@users/USER_UPDATE_METHOD_OF_PAYMENT_FAILURE';
export const USER_GET_PAYMENT_DETAILS = '@users/USER_GET_PAYMENT_DETAILS';
export const USER_GET_PAYMENT_DETAILS_SUCCESS = '@users/USER_GET_PAYMENT_DETAILS_SUCCESS';
export const USER_GET_PAYMENT_DETAILS_FAILURE = '@users/USER_GET_PAYMENT_DETAILS_FAILURE';
export const USER_SET_FAILED_INVOICES_PROCESSING = '@users/USER_SET_FAILED_INVOICES_PROCESSING';
export const USER_REQUEST_ACCOUNT_DELETION = '@users/USER_REQUEST_ACCOUNT_DELETION';
export const USER_GET_PUBKEY_INTENT = '@USER_GET_PUBKEY_INTENT';
export const USER_GET_PUB_KEY_INTENT_SUCCESS = '@USER_GET_PUB_KEY_INTENT_SUCCESS';
