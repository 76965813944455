import { call, put, select } from 'redux-saga/effects';
import UserClient from '../../api/UserClient';
import { unsubscribeFromAPlanSuccess, unsubscribeFromAPlanFailure, userGetRequest } from '../../redux/user/actions';
import { messagesShow } from '../../redux/messages/actions';
import { subscriptionIdSelector, profileIdSelector } from '../../selectors/user';

export default function* unsubscribeFromAPlan() {
    const subscriptionId = yield select(subscriptionIdSelector);
    const profileId = yield select(profileIdSelector);
    try {
        yield call(UserClient.unsubscribeFromAPlan, { subscriptionId, profileId });
        yield put(unsubscribeFromAPlanSuccess());
        yield put(messagesShow('You subscribed successfully to the free plan', 'success'));
        yield put(userGetRequest());
    } catch (e) {
        yield put(unsubscribeFromAPlanFailure());
        yield put(messagesShow('The subscription to the free plan failed', 'error'));
    }
}
