/* eslint-disable consistent-return */
import { call, delay, put, select } from 'redux-saga/effects';
import moment from 'moment';

import BookingClient from '../../api/BookingClient';
import {
    createNewBookingSBRequestFailed,
    createNewBookingSBRequestSuccessful,
    setNewBookingConfirmAddingDialogOpened,
    fetchSBRequestByID,
    updateGoToAccountDialog,
} from '../../redux/bookings/actions';

import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import getUser from '../user/get';
import { listProfileSelector, userIdSelector } from '../../selectors/user';
import { allCitiesSelector, newBookingDataSelector } from '../../selectors/bookings';
import { displayMessageForBookingError, getGoToMyAccountStatusMessage } from '../../helpers/bookings';
import { setSessionStorageItem } from '../../helpers/LocalStorage';
import agreeToCityTerms from '../user/agreeToCityTerms';

export default function*({ data }) {
    try {
        const profiles = yield select(listProfileSelector);
        if (profiles && profiles.length === 0) {
            yield call(getUser);
        }
        const userId = yield select(userIdSelector);
        const bookingData = yield select(newBookingDataSelector);
        const selectedCities = yield select(allCitiesSelector);
        const {
            selectedProfileId,
            selectedServiceId,
            selectedStartDate,
            selectedEndDate,
            selectedStationId,
            selectedModelId,
            selectedCityId,
            notes,
        } = bookingData;

        if (data && data.agreeToTerms) {
            yield call(agreeToCityTerms);
        }
        const selectedCity = selectedCities.find(c => c.id === selectedCityId);

        let formattedStartDate = selectedStartDate || null;
        let formattedEndDate = selectedEndDate || null;

        if (selectedCity && selectedCity.timeZone) {
            formattedStartDate =
                formattedStartDate &&
                moment
                    .tz(formattedStartDate, selectedCity.timeZone)
                    .utc()
                    .format();
            formattedEndDate =
                formattedEndDate &&
                moment
                    .tz(formattedEndDate, selectedCity.timeZone)
                    .utc()
                    .format();
        } else {
            formattedStartDate =
                selectedStartDate &&
                moment(formattedStartDate)
                    .utc()
                    .format();
            formattedEndDate =
                formattedEndDate &&
                moment(formattedEndDate)
                    .utc()
                    .format();
        }

        const payload = {
            profileId: selectedProfileId,
            serviceId: selectedServiceId,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            modelId: selectedModelId,
            station: selectedStationId,
            requiresActionReturnURL: `${window.location.origin}/stripe_redirection_booking`,
            userId,
            notes,
        };
        const bookingResult = yield call(BookingClient.createScheduledBookingRequest, payload);

        yield delay(500);

        yield put(fetchSBRequestByID({ id: bookingResult.id }));

        yield put(setNewBookingConfirmAddingDialogOpened(true));

        const response = yield call(BookingClient.getBookingById, bookingResult.id);

        if (response.status !== 'CANCELLED') {
            yield delay(5000);
        }

        if (response.status === 'CANCELLED') {
            throw new Error(response && response.failureReason ? response.failureReason : 'error');
        } else if (response.status === 'PENDING_PAYMENT') {
            const paymentIntentsObj = response.paymentIntents[0];
            const redirectUrl = paymentIntentsObj.nextAction.nextActionRedirectUrl.url;
            const paymentStatus = paymentIntentsObj.status;
            const { pspPublishableKey } = paymentIntentsObj;

            // Persist the pspPublishableKey so it can survive the redirection to hooks.stripe.com in case of 3DS or 3DS2
            if (pspPublishableKey) {
                setSessionStorageItem('pspPublishableKey', pspPublishableKey);
            }

            if (paymentStatus === 'REQUIRES_ACTION') {
                yield put(
                    createNewBookingSBRequestSuccessful({
                        paymentStatus,
                        redirectUrl,
                    })
                );
            } else {
                yield put(createNewBookingSBRequestSuccessful({ paymentStatus: null, redirectUrl: null }));
            }
        } else {
            yield put(createNewBookingSBRequestSuccessful({ paymentStatus: null, redirectUrl: null }));
        }
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            console.error('createSBRequest', error);
            yield put(createNewBookingSBRequestFailed());
            let errorMessage = 'internalServerError';

            if (error && error.message) {
                if (typeof error.message === 'string') {
                    // error comes from "throw new Error" => we access it in error.message
                    errorMessage = error.message;
                } else if (error.message.message) {
                    const goToAccountStatus = getGoToMyAccountStatusMessage(data.l10n, error.message.message);

                    if (goToAccountStatus) {
                        return yield put(updateGoToAccountDialog({ message: goToAccountStatus, open: true }));
                    }
                    errorMessage = displayMessageForBookingError(error.message.message);
                }
            }

            yield put(messagesShow(errorMessage, 'error'));
        }
    }
}
