import moment from 'moment-timezone';

import unselectedStationIcon from '../components/CreateBooking/images/station_unselected.png';
import selectedStationIcon from '../components/CreateBooking/images/station_selected.png';
import hoveredStationIcon from '../components/CreateBooking/images/station_hovered.png';

export const SERVICE_ROUND_TRIP_BOOKING = 'ROUND_TRIP_BOOKING';
export const SERVICE_SCHEDULED_BOOKING_STATION = 'SCHEDULED_BOOKING_STATION';

export const APPROVED = 'APPROVED';
export const TOU_UNACCEPTED = 'TOU_UNACCEPTED';
export const MOP_INVALID = 'MOP_INVALID';
export const MOP_EXPIRED = 'MOP_EXPIRED';
export const MOP_MISSING = 'MOP_MISSING';
export const DOC_EXPIRED = 'DOC_EXPIRED';
export const DOC_MISSING = 'DOC_MISSING';
export const DOC_INVALID = 'DOC_INVALID';
export const SERVICE_REG_UNREGISTERED = 'SERVICE_REG_UNREGISTERED';
export const SERVICE_REG_INCOMPLETE = 'SERVICE_REG_INCOMPLETE';
export const DOC_PENDING_REVIEW = 'DOC_PENDING_REVIEW';
export const SERVICE_REG_SUSPENDED = 'SERVICE_REG_SUSPENDED';
export const SERVICE_REG_PENDING = 'SERVICE_REG_PENDING';
export const SERVICE_REG_REJECTED = 'SERVICE_REG_REJECTED';
export const SUSPENDED = 'SUSPENDED';
export const ARCHIVED = 'ARCHIVED';
export const REJECTED = 'REJECTED';
export const OUTSTANDING_BALANCE = 'OUTSTANDING_BALANCE';
export const ENTITY_PENDING = 'ENTITY_PENDING';
export const ENTITY_SUSPENDED = 'ENTITY_SUSPENDED';
export const ENTITY_REJECTED = 'ENTITY_REJECTED';
export const ENTITY_ARCHIVED = 'ENTITY_ARCHIVED';

export const getGoToMyAccountStatusMessage = (l10n, status) => {
    if (!status || !l10n) {
        return null;
    }

    let message = '';
    switch (status) {
        case SERVICE_REG_UNREGISTERED:
            message = l10n.bookingErrorMessageUpdateAccount;
            break;
        case SERVICE_REG_INCOMPLETE:
            message = l10n.bookingErrorMessageUpdateAccount;
            break;
        case DOC_MISSING:
            message = l10n.bookingErrorMessageDocMissing;
            break;
        case DOC_INVALID:
            message = l10n.bookingErrorMessageDocInvalid;
            break;
        case DOC_EXPIRED:
            message = l10n.bookingErrorMessageDocExpired;
            break;
        case MOP_MISSING:
            message = l10n.bookingErrorMessageMopMissing;
            break;
        case MOP_INVALID:
            message = l10n.bookingErrorMessageMopInvalid;
            break;
        case MOP_EXPIRED:
            message = l10n.bookingErrorMessageMopExpired;
            break;
        default:
            break;
    }

    return message;
};

export const NEW_BOOKING_STEP_ONE = 1;
export const NEW_BOOKING_STEP_TWO = 2;
const acceptedStatusesOnPrivateService = ['approved', 'pending', 'incomplete'];

const getCannotBookStatusMessage = (l10n, status) => {
    if (!l10n || !status) {
        return null;
    }

    let message = null;
    switch (status) {
        case DOC_PENDING_REVIEW:
            message = l10n.bookingErrorMessageDocPendingReview;
            break;
        case SERVICE_REG_PENDING:
            message = l10n.bookingErrorMessageServicePending;
            break;
        case OUTSTANDING_BALANCE:
            message = l10n.bookingErrorMessageOutstandingBalance;
            break;
        case SERVICE_REG_SUSPENDED:
        case SERVICE_REG_REJECTED:
        case SUSPENDED:
        case ARCHIVED:
        case REJECTED:
        case ENTITY_PENDING:
        case ENTITY_SUSPENDED:
        case ENTITY_REJECTED:
        case ENTITY_ARCHIVED:
            message = l10n.createBookingPage.profileNotApprovedOnService;
            break;
        default:
            break;
    }

    return message;
};

export const checkIfStatusError = (selectedServiceId, selectedProfile, l10n) => {
    let cannotBookErrorMessage = '';
    if (selectedProfile) {
        const selectedService = selectedProfile.services.find(s => s.id === selectedServiceId);
        const statusForSelectedService = selectedService && selectedService.status;

        cannotBookErrorMessage = getCannotBookStatusMessage(l10n, selectedProfile.status);
        if (!cannotBookErrorMessage && statusForSelectedService) {
            cannotBookErrorMessage = getCannotBookStatusMessage(l10n, statusForSelectedService);
        }
    }
    return cannotBookErrorMessage;
};

export const getSBValidServices = (selectedCity, profileServices, isUserAuthenticated = false) => {
    let result = [];

    if (selectedCity && Array.isArray(selectedCity.services)) {
        const citySBservices =
            selectedCity.services.filter(
                service =>
                    (service.type === SERVICE_ROUND_TRIP_BOOKING ||
                        service.type === SERVICE_SCHEDULED_BOOKING_STATION) &&
                    service.status === 'ACTIVE' &&
                    (isUserAuthenticated || (!isUserAuthenticated && service.visibility === 'PUBLIC'))
            ) || [];

        result = citySBservices;

        if (profileServices && isUserAuthenticated) {
            let profileServicesWithStatus = [];
            Object.entries(profileServices).forEach(([status, list]) => {
                if (Array.isArray(list) && list.length > 0) {
                    list.forEach(serviceId => profileServicesWithStatus.push({ serviceId, statusForProfile: status }));
                }
            });
            if (profileServicesWithStatus.length === 0) {
                return [];
            }

            const cityServicesWithProfileStatus =
                (profileServicesWithStatus.length > 0 &&
                    citySBservices.filter(cityService => {
                        const foundService = profileServicesWithStatus.find(
                            profileService => profileService.serviceId === cityService.id
                        );
                        if (
                            foundService &&
                            (cityService.visibility === 'PUBLIC' ||
                                acceptedStatusesOnPrivateService.includes(foundService.statusForProfile))
                        ) {
                            return true;
                        }
                        return false;
                    })) ||
                [];

            if (cityServicesWithProfileStatus.length > 0) {
                profileServicesWithStatus = cityServicesWithProfileStatus.map(service => {
                    const foundService = profileServicesWithStatus.find(s => s.serviceId === service.id);
                    return { ...service, statusForProfile: foundService ? foundService.statusForProfile : '' };
                });

                result = profileServicesWithStatus;
            }
        }
    }
    return result;
};

const convertDegreesToRadians = value => {
    return (value * Math.PI) / 180;
};
export const calculateCrowFliesDistance = (lat1, lon1, lat2, lon2, isDistanceInMiles = false) => {
    const R = isDistanceInMiles ? 3958.8 : 6371; // Radius of the earth
    const dLat = convertDegreesToRadians(lat2 - lat1);
    const dLon = convertDegreesToRadians(lon2 - lon1);
    const newLat1 = convertDegreesToRadians(lat1);
    const newLat2 = convertDegreesToRadians(lat2);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(newLat1) * Math.cos(newLat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d.toFixed(2);
};

export const sortStationsByCoordinates = (stations, selectedStationId, isDistanceInMiles = false) => {
    let result = [];
    if (Array.isArray(stations) && stations.length <= 1) {
        return stations;
    }
    if (Array.isArray(stations) && !!selectedStationId) {
        const newStations = stations.filter(station => station.stationId !== selectedStationId);
        const selectedStation = stations.find(station => station.stationId === selectedStationId) || null;
        newStations.forEach(function callBack(station, indexStation) {
            if (
                selectedStation &&
                Array.isArray(station.coordinates) &&
                station.coordinates[0] &&
                station.coordinates[1]
            ) {
                const stationLat = station.coordinates[1];
                const stationLon = station.coordinates[0];
                const selectedStationLat = selectedStation.coordinates[1];
                const selectedStationLon = selectedStation.coordinates[0];
                const crowFliesDistance = calculateCrowFliesDistance(
                    selectedStationLat,
                    selectedStationLon,
                    stationLat,
                    stationLon,
                    isDistanceInMiles
                );
                this[indexStation].crowFliesDistance = crowFliesDistance;
            }
        }, newStations);
        if (selectedStation) {
            selectedStation.crowFliesDistance = (0).toFixed(2);
            result.push(selectedStation);
        }
        result = [
            ...result,
            ...newStations.sort(
                (a, b) => a.crowFliesDistance && b.crowFliesDistance && a.crowFliesDistance - b.crowFliesDistance
            ),
        ];
    }
    return result;
};

export const displayMessageForBookingError = error => {
    if (!error) {
        return 'internalServerError';
    }
    if (error.includes('maximum concurrent trips per service has been reached')) {
        return 'bookingErrorMessageMaxConcurrentTrips';
    }
    if (error.includes('Cannot process this booking request because no vehicles model available at requested time')) {
        return 'bookingErrorVehicleNotAvailableAtRequestedTime';
    }
    if (error.includes('Invalid Booking request')) {
        return 'bookingErrorInvalidBookingRequest';
    }
    switch (error) {
        case 'MOP_MISSING':
            return 'bookingErrorMessageMopMissing';
        case 'MOP_EXPIRED':
            return 'bookingErrorMessageMopExpired';
        case 'MOP_INVALID':
            return 'bookingErrorMessageMopInvalid';
        case 'MOP_UPDATE_FAILED':
            return 'bookingErrorMessageMopUpdateFailed';
        case 'DOC_MISSING':
            return 'bookingErrorMessageDocMissing';
        case 'DOC_EXPIRED':
            return 'bookingErrorMessageDocExpired';
        case 'DOC_INVALID':
            return 'bookingErrorMessageDocInvalid';
        case 'DOC_PENDING_REVIEW':
            return 'bookingErrorMessageDocPendingReview';
        case 'OUTSTANDING_BALANCE':
            return 'bookingErrorMessageOutstandingBalance';
        case 'SERVICE_REG_UNREGISTERED':
        case 'SERVICE_REG_INCOMPLETE':
            return 'bookingErrorMessageUpdateAccount';
        case 'SERVICE_REG_PENDING':
            return 'bookingErrorMessageServicePending';
        case 'ARCHIVED':
        case 'REJECTED':
        case 'SERVICE_REG_REJECTED':
        case 'SERVICE_REG_SUSPENDED':
        case 'SUSPENDED':
            return 'bookingErrorMessageAccountError';
        case 'ENTITY_PENDING':
        case 'ENTITY_SUSPENDED':
        case 'ENTITY_REJECTED':
        case 'ENTITY_ARCHIVED':
            return 'bookingErrorMessageUnhandled';
        default:
            return 'internalServerError';
    }
};

export const getStationAddress = station => {
    if (!station) {
        return '';
    }
    const { address, postal_code: postalCode, city } = station;

    let newAddress = '';
    let newPostalCode = '';

    if (address) {
        newAddress = postalCode || city ? `${address}, ` : address;
    }
    if (postalCode) {
        newPostalCode = city ? `${postalCode} ` : postalCode;
    }

    return `${newAddress}${newPostalCode}${city || ''}`;
};

const getRightSizeForIcon = (imageWidth, type) => {
    const wantedSize = type === 'selected' ? 50 : 40;
    const result = Math.round((1 / (imageWidth / wantedSize)) * 1000) / 1000;

    return result;
};

const addImageAndSourceAndLayer = ({ mapCurrent, image, imageName, imageType, sourceName, layerId }) => {
    // Add the image to the map style.
    mapCurrent.addImage(imageName, image);

    mapCurrent.addSource(sourceName, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    });

    // Add a layer to use the image to represent the data.
    mapCurrent.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceName, // reference the data source
        layout: {
            'icon-image': imageName, // reference the image
            'icon-size': getRightSizeForIcon(image.width, imageType),
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
        },
    });
};

const images = {
    hovered: hoveredStationIcon,
    unselected: unselectedStationIcon,
    selected: selectedStationIcon,
};

export const importImageFromConf = ({ mapCurrent, imgUrl, imageName, sourceName, layerId, imageType }) => {
    let useDefaultIcon = !imgUrl;

    if (!useDefaultIcon) {
        // an image url is provided through the conf, we fetch the image
        // note that png and jpeg are supported but not SVG
        mapCurrent.loadImage(imgUrl, (error, image) => {
            if (image) {
                addImageAndSourceAndLayer({
                    mapCurrent,
                    image,
                    imageName,
                    sourceName,
                    layerId,
                    imageType,
                });
            } else {
                // error fetching icon from conf, we use default icon
                console.error(`Error fetching ${imageName} from conf`, error);
                useDefaultIcon = true;
            }
        });
    }

    if (useDefaultIcon) {
        // no image url in the conf or error fetching it, we use default icon
        mapCurrent.loadImage(images[imageType], (err, image) => {
            if (err) throw new Error(`Unable to load default icon ${imageName}`, err);

            addImageAndSourceAndLayer({
                mapCurrent,
                image,
                imageName,
                sourceName,
                layerId,
                imageType,
            });
        });
    }
};

export const dateWithTimeZone = (date, timeZone) => {
    let displayedEndDate = null;
    if (date) {
        displayedEndDate = moment(date);
        if (timeZone) {
            displayedEndDate = moment.tz(date, timeZone);
        }
    } else {
        displayedEndDate = moment();
    }

    return displayedEndDate;
};

export const getStationJson = (sourceName, station) => {
    if (!station) {
        return null;
    }
    return {
        type: 'Feature',
        properties: {
            source: sourceName,
            ...station,
        },
        geometry: {
            type: 'Point',
            coordinates: station.coordinates,
        },
    };
};
