import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { messagesShow } from '../../redux/messages/actions';
import { userUpdateDocumentFiles } from '../../redux/user/actions';
import { uploadDocumentAttachementHelper } from '../../helpers/user';
import { USER_UPLOAD_UPLOADING, USER_GET_REQUEST } from '../../redux/user/types';
import { UPLOAD_STATUS } from '../../constants/Files';

export default function* uploadDocs({ payload }) {
    const { documentType, temporaryData, uploadQueue } = payload;
    try {
        // display loader
        yield put({
            type: USER_UPLOAD_UPLOADING,
            payload: {
                documentType: documentType.type,
                uploadQueue: {
                    name: uploadQueue.endpoint.name,
                    uploadState: UPLOAD_STATUS.uploading,
                },
            },
        });

        // upload
        const result = yield call(uploadDocumentAttachementHelper, UserClient, { uploadQueue });
        yield put(
            userUpdateDocumentFiles({
                documentType: documentType.type,
                files: [
                    {
                        name: uploadQueue.endpoint.name,
                        url: temporaryData,
                    },
                ],
            })
        );
        yield put({
            type: USER_UPLOAD_UPLOADING,
            payload: {
                documentType: documentType.type,
                uploadQueue: {
                    name: uploadQueue.endpoint.name,
                    uploadState: result ? UPLOAD_STATUS.done : UPLOAD_STATUS.failed,
                },
            },
        });

        // show a message
        yield put(messagesShow('updateDocumentSuccessful', 'success'));
        yield put({ type: USER_GET_REQUEST });
    } catch (e) {
        yield put({
            type: USER_UPLOAD_UPLOADING,
            payload: {
                documentType: documentType.type,
                uploadQueue: {
                    name: uploadQueue.endpoint.name,
                    uploadState: UPLOAD_STATUS.failed,
                },
            },
        });
        yield put(messagesShow('internalServerError', 'error'));
        yield put({ type: USER_GET_REQUEST });
    }
}
