import { call, put } from 'redux-saga/effects';

import BookingClient from '../../api/BookingClient';
import { cancelSBRequestSuccessful, cancelSBRequestFailed, fetchSBRequests } from '../../redux/bookings/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload }) {
    try {
        yield call(BookingClient.cancelScheduledBookingRequest, payload);
        yield put(cancelSBRequestSuccessful());
        yield put(messagesShow('MyBookingsCancelBookingSuccessMessage', 'success'));
        yield put(fetchSBRequests());
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(cancelSBRequestFailed());
            yield put(messagesShow('MyBookingsCancelBookingFailedMessage', 'error'));
            yield put(fetchSBRequests());
        }
    }
}
