import { CLEAR, MESSAGES_SHOW, MESSAGES_HIDE } from './types';

const initState = {
    open: false,
    text: '',
    type: 'error',
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case CLEAR:
            return {
                ...initState,
            };

        case MESSAGES_SHOW:
            return {
                ...state,
                open: true,
                ...action.payload,
            };

        case MESSAGES_HIDE:
            return {
                ...state,
                open: false,
            };

        default:
            return state;
    }
};

export default reducer;
