import { all, takeLatest } from 'redux-saga/effects';

import { ACTION_GET_STATION_INFORMATION, ACTION_GET_STATION_ACCESS_INSTRUCTIONS } from '../../redux/stations/types';

import { getStationInformationSaga, getStationAccessInstructionsSaga } from './get';

export default function*() {
    yield all([takeLatest(ACTION_GET_STATION_INFORMATION, getStationInformationSaga)]);
    yield all([takeLatest(ACTION_GET_STATION_ACCESS_INSTRUCTIONS, getStationAccessInstructionsSaga)]);
}
