import {
    PACKAGES_REQUEST,
    PACKAGES_REQUEST_FAILED,
    PACKAGES_REQUEST_SUCCESSFUL,
    PACKAGE_PURCHASE_FAILED,
    PACKAGE_PURCHASE_REQUEST,
    PACKAGE_PURCHASE_SUCCESSFUL,
    SELECT_PACKAGE,
    RESET_DIALOG_STEP,
    START_PACKAGES_REQUEST,
    START_PURCHASE_REQUEST,
} from './types';

// Packages list
export const packagesRequest = () => ({ type: PACKAGES_REQUEST });
export const startPackagesRequest = () => ({ type: START_PACKAGES_REQUEST });
export const packagesRequestSuccessful = (packages, currency) => ({
    type: PACKAGES_REQUEST_SUCCESSFUL,
    payload: { packages, currency },
});
export const packagesRequestFailed = () => ({ type: PACKAGES_REQUEST_FAILED });

// Confirm purchase
export const packagePurchaseRequest = id => ({ type: PACKAGE_PURCHASE_REQUEST, payload: { id } });
export const startPurchaseRequest = () => ({ type: START_PURCHASE_REQUEST });
export const packagePurchaseSuccessful = () => ({ type: PACKAGE_PURCHASE_SUCCESSFUL });
export const packagePurchaseFailed = () => ({ type: PACKAGE_PURCHASE_FAILED });

// Various
export const selectPackage = item => ({ type: SELECT_PACKAGE, payload: { item } });
export const resetDialogStep = () => ({ type: RESET_DIALOG_STEP });
