import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*() {
    try {
        yield call(UserClient.requestAccountDeletionEndpoint);
        yield put(logoutRequest());
        yield put(messagesShow('accountUserDeactivatedSuccess', 'success'));
    } catch (error) {
        yield put(messagesShow('accountUserDeactivatedFailed', 'error'));
    }
}
