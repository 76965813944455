import {
    GET_INVOICES_REPLAY_DATA,
    GET_INVOICES_REPLAY_DATA_SUCCESS,
    GET_INVOICES_REPLAY_DATA_FAILURE,
    SET_SHOULD_REDIRECT_TO_INVOICES,
    PAY_INVOICES,
    SET_INVOICE_REPLAY_FETCHING,
    SET_IS_PAYMENT_PROCESSING,
    PAY_FAILED_INVOICES,
    SET_DISPLAY_REDIRECT_TO_INVOICES,
    CLEAR_INVOICES_REPLAY,
} from './types';

export const getInvoicesReplayData = () => ({ type: GET_INVOICES_REPLAY_DATA });
export const getInvoicesReplayDataSuccess = payload => ({ type: GET_INVOICES_REPLAY_DATA_SUCCESS, payload });
export const getInvoicesReplayDataFailure = () => ({ type: GET_INVOICES_REPLAY_DATA_FAILURE });
export const setShouldRedirectToInvoices = payload => ({ type: SET_SHOULD_REDIRECT_TO_INVOICES, payload });
export const payInvoices = () => ({ type: PAY_INVOICES });
export const setInvoiceReplayFetching = payload => ({ type: SET_INVOICE_REPLAY_FETCHING, payload });
export const setIsPaymentProcessing = payload => ({ type: SET_IS_PAYMENT_PROCESSING, payload });
export const payFailedInvoices = () => ({ type: PAY_FAILED_INVOICES });
export const setDisplayRedirectToInvoices = payload => ({ type: SET_DISPLAY_REDIRECT_TO_INVOICES, payload });
export const clearInvoicesReplay = () => ({ type: CLEAR_INVOICES_REPLAY });
