import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import setDisplayName from 'recompose/setDisplayName';

export const LocaleContext = createContext({});
const { Provider, Consumer } = LocaleContext;

let _setLanguage = () => null;

export const initSetLanguage = f => (_setLanguage = f);
export const setLanguage = lg => _setLanguage(lg);

export const LocaleProvider = ({ language, l10n, onSelectedLanguage, availableLanguages, children }) => (
    <Provider value={{ language, l10n, onSelectedLanguage, availableLanguages }}>{children}</Provider>
);

LocaleProvider.propTypes = {
    language: PropTypes.string.isRequired,
    l10n: PropTypes.shape({}).isRequired,
    onSelectedLanguage: PropTypes.func.isRequired,
    availableLanguages: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    children: PropTypes.shape({}),
};

export const withLocale = Component =>
    setDisplayName('withLocale')(props => <Consumer>{locale => <Component {...props} locale={locale} />}</Consumer>);
