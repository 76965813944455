const storageLocal = {};
const storageSession = {};

export const getLocalStorageItem = key => {
    if (!key) throw new Error('key is null or empty');
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error('Unable to get data from local storage, Error:', err);
        return storageLocal[key];
    }
};

export const setLocalStorageItem = (key, state) => {
    if (!key) throw new Error('key is null or empty');
    try {
        if (state) {
            const serializedState = JSON.stringify(state);
            localStorage.setItem(key, serializedState);
        } else {
            localStorage.removeItem(key);
        }
    } catch (err) {
        storageLocal[key] = state;
        console.error(
            'Unable to set data into local storage, instead it has been set into a global variable, Error:',
            err
        );
    }
};

export const getSessionStorageItem = key => {
    if (!key) throw new Error('key is null or empty');
    try {
        const serializedState = sessionStorage.getItem(key);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error('Unable to get data from session storage, Error:', err);
        return storageSession[key];
    }
};

export const setSessionStorageItem = (key, state) => {
    if (!key) throw new Error('key is null or empty');
    try {
        if (state) {
            const serializedState = JSON.stringify(state);
            sessionStorage.setItem(key, serializedState);
        } else {
            sessionStorage.removeItem(key);
        }
    } catch (err) {
        storageSession[key] = state;
        console.error(
            'Unable to set data into session storage, instead it has been set into a global variable, Error:',
            err
        );
    }
};
