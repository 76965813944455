import { call, put, select } from 'redux-saga/effects';

import StationClient from '../../api/StationClient';
import UserClient from '../../api/UserClient';
import { messagesShow } from '../../redux/messages/actions';
import {
    setStationInformationSuccessAction,
    setStationInformationFailureAction,
    setStationAccessInstructionsAction,
} from '../../redux/stations/actions';
import fetchActiveSBRequests from '../bookings/fetchPaidSBRequests';
import { myBookingsDataSelector } from '../../selectors/bookings';

export function* getStationInformationSaga({ payload }) {
    try {
        if (payload && payload.stationId) {
            const allStationInformation = yield call(StationClient.getStationInformation);
            const allStationInformationOpeningHours = yield call(StationClient.getStationOpeningHours);
            let stationName = '';
            let stationAddress = '';
            let stationDescription = [];
            let stationOpeningHours = {};
            let timeZone = '';
            let city = '';

            if (Array.isArray(allStationInformation)) {
                let stationInformation = null;

                allStationInformation.some(({ content, cityId }) => {
                    const stationInformationFound = content.features.find(
                        station => station.properties.stationId === payload.stationId
                    );

                    if (stationInformationFound) {
                        stationInformation = stationInformationFound;
                        city = cityId;

                        return true;
                    }

                    return false;
                });

                if (stationInformation) {
                    const allCities = yield call(UserClient.getCities);
                    const stationInformationPoiId = stationInformation.properties.poiId;

                    city = allCities && allCities.find(c => c.id === city);
                    timeZone = city && city.timeZone;
                    stationName = stationInformation.properties.name;
                    stationAddress = `${
                        stationInformation.properties.address ? `${stationInformation.properties.address} ` : ''
                    }${
                        stationInformation.properties.postal_code ? `${stationInformation.properties.postal_code} ` : ''
                    }${stationInformation.properties.city || ''}`;

                    if (stationInformationPoiId && Array.isArray(allStationInformationOpeningHours)) {
                        const stationOpeningHoursData = allStationInformationOpeningHours.find(
                            station => station.id === stationInformationPoiId
                        );

                        if (stationOpeningHoursData) {
                            if (Array.isArray(stationOpeningHoursData.descriptions)) {
                                stationDescription = [...stationOpeningHoursData.descriptions];
                            }

                            if (stationOpeningHoursData.timeDetails) {
                                stationOpeningHours = { ...stationOpeningHoursData.timeDetails };
                            }
                        }
                    }
                }
            }

            yield put(
                setStationInformationSuccessAction({
                    name: stationName,
                    address: stationAddress,
                    description: stationDescription,
                    openingHours: stationOpeningHours,
                    timeZone,
                })
            );
        }
    } catch (error) {
        yield put(setStationInformationFailureAction('Error'));

        console.error('[ERROR][SAGA] getStationInformationSaga', error);
    }
}

export function* getStationAccessInstructionsSaga({ payload }) {
    try {
        if (payload && payload.stationId) {
            const { stationId } = payload;

            // we first verify if the user has an active booking
            yield call(fetchActiveSBRequests);
            const activeBookings = yield select(myBookingsDataSelector);

            let formattedAssets = [];

            // we only show the station access instructions if the user has an active booking linked to stationId from payload
            // we also check dropOffStation in case the user has a one way booking and the stationId is the dropOffStation
            if (
                Array.isArray(activeBookings) &&
                activeBookings.length > 0 &&
                activeBookings.some(booking => booking.station === stationId || booking.dropOffStation === stationId)
            ) {
                const stationInstructions = yield call(StationClient.getStationInstructionAssetsEndpoint, stationId);

                if (stationInstructions && stationInstructions.assets && stationInstructions.assets.length > 0) {
                    formattedAssets = stationInstructions.assets.map(asset => ({
                        ...asset,
                        text: JSON.parse(asset.text),
                    }));
                }
            }

            yield put(setStationAccessInstructionsAction(formattedAssets));
        }
    } catch (error) {
        if ((error && error.code === 404) || (error.response && error.response.status === 404)) {
            // if 404, it means this station has no assets, no need to display error message
            yield put(setStationAccessInstructionsAction([]));
        } else {
            yield put(messagesShow('internalServerError', 'error'));
        }

        console.error('[ERROR][SAGA] getStationAccessInstructionsSaga', error);
    }
}
