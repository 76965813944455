import axios from 'axios';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/LocalStorage';
import { addAlternateTranslations } from '../helpers/translations';

export const getValidLang = (lg, availableLanguages, defaultLang) =>
    lg && availableLanguages.findIndex(lang => lang === lg) > -1 ? lg : defaultLang;

export const getLocalLanguage = () => getLocalStorageItem('language');

export const getDefaultFleetLanguage = () => getLocalStorageItem('defaultFleetLanguage');

export const saveLocalLanguage = language => setLocalStorageItem('language', language);

export const saveDefaultFleetLanguage = defaultLang => setLocalStorageItem('defaultFleetLanguage', defaultLang);

export const getLabelsForLanguage = async (language, alternateTranslations) => {
    const l10n = await axios
        .get(
            `/fe-api/anonymous/fo/localise/${language}?${process.env.REACT_APP_VERSION ||
                process.env.REACT_APP_BUILD_ID}`
        )
        .then(({ data }) => data);
    if (Array.isArray(alternateTranslations) && alternateTranslations.length > 0) {
        addAlternateTranslations(l10n, alternateTranslations);
    }
    return {
        locale: l10n,
    };
};

export const getAvailableLocales = async () => {
    const locales = await axios
        .get(`/fe-api/anonymous/fo/localise/locales?${process.env.REACT_APP_VERSION || process.env.REACT_APP_BUILD_ID}`)
        .then(({ data }) => data);

    return locales;
};
