import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import setDisplayName from 'recompose/setDisplayName';

export const ConfigurationContext = createContext({});
const { Provider, Consumer } = ConfigurationContext;

/**
 * Return custom url for path.
 * @param {string} lang - lang for url.
 * @param {string} path - The path value.
 *
 * path :
 *  - faq
 *  - register
 *  - reset_lost_password
 *  - send_lost_password_email
 *  - account
 *  - contact
 *  - termsConditions
 *
 * format possible
 *
 * faq : "http://tot.com"
 * faq : { "en_GB":"http://tot.com"}
 * faq : { "en_GB" : { "cityid": "http://tot.com", "default" : "http://tot2.com"}}
 */
export const getNavigationBarPath = api => lang => (path, city = 'default') => {
    if (!api.navigationBarPath || !api.navigationBarPath[path]) return null;

    if (typeof api.navigationBarPath[path] === 'string') return api.navigationBarPath[path];
    let pathConf = '';
    const pathConfForLang = api.navigationBarPath[path][lang];
    const pathConfDefaultLang = api.navigationBarPath[path][api.defaultLang];

    if (pathConfForLang) {
        pathConf = pathConfForLang;
    } else if (pathConfDefaultLang) {
        pathConf = pathConfDefaultLang;
    }

    if (typeof pathConf === 'string') return pathConf;
    return pathConf[city] ? pathConf[city] : pathConf.default;
};

export const ConfigurationProvider = ({ api, contacts, faq, children, adyen, adyenApi, stripe, fuelCard }) => (
    <Provider value={{ api, contacts, faq, adyen, adyenApi, stripe, fuelCard }}>{children}</Provider>
);

ConfigurationProvider.propTypes = {
    api: PropTypes.shape({}).isRequired,
    contacts: PropTypes.shape({}).isRequired,
    faq: PropTypes.shape({}).isRequired,
    adyen: PropTypes.shape({}).isRequired,
    adyenApi: PropTypes.shape({}).isRequired,
    stripe: PropTypes.shape({}),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    fuelCard: PropTypes.shape({}),
};

export const withConfiguration = Component =>
    setDisplayName('withConfiguration')(props => (
        <Consumer>
            {config => (
                <Component
                    {...props}
                    configuration={{
                        ...config,
                        getNavigationBarPath: getNavigationBarPath(config.api),
                    }}
                />
            )}
        </Consumer>
    ));
