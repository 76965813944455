export const GET_INVOICES_REPLAY_DATA = '@invoicesReplay/GET_INVOICES_REPLAY_DATA';
export const GET_INVOICES_REPLAY_DATA_SUCCESS = '@invoicesReplay/GET_INVOICES_REPLAY_DATA_SUCCESS';
export const GET_INVOICES_REPLAY_DATA_FAILURE = '@invoicesReplay/GET_INVOICES_REPLAY_DATA_FAILURE';
export const SET_SHOULD_REDIRECT_TO_INVOICES = '@invoicesReplay/SET_SHOULD_REDIRECT_TO_INVOICES';
export const PAY_INVOICES = '@invoicesReplay/PAY_INVOICES';
export const SET_INVOICE_REPLAY_FETCHING = '@invoicesReplay/SET_INVOICE_REPLAY_FETCHING';
export const SET_IS_PAYMENT_PROCESSING = '@invoicesReplay/SET_IS_PAYMENT_PROCESSING';
export const PAY_FAILED_INVOICES = '@invoicesReplay/PAY_FAILED_INVOICES';
export const SET_DISPLAY_REDIRECT_TO_INVOICES = '@invoicesReplay/SET_DISPLAY_REDIRECT_TO_INVOICES';
export const CLEAR_INVOICES_REPLAY = '@invoicesReplay/CLEAR_INVOICES_REPLAY';
