import { call, put } from 'redux-saga/effects';

import BookingClient from '../../api/BookingClient';
import { fetchSBRequestsSuccessful, fetchSBRequestsFailed } from '../../redux/bookings/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*() {
    try {
        const poisResults = yield call(BookingClient.getPOIs);
        const poisDetailsData = [];
        const stationsId = [];
        poisResults.forEach(poiResult => {
            if (poiResult.content && Array.isArray(poiResult.content.features)) {
                poiResult.content.features.forEach(poiData => {
                    if (poiData && poiData.properties && poiData.properties.stationId) {
                        poisDetailsData.push({ ...poiData.properties });
                        stationsId.push(poiData.properties.stationId);
                    }
                });
            }
        });

        const modelsDetailsResults = yield call(BookingClient.getModels);
        const modelsId = modelsDetailsResults.map(model => model.id);

        const bookingsResult = yield call(BookingClient.getBookings);
        bookingsResult.forEach(function callBack(booking, indexBooking) {
            let newBooking = { ...booking };
            if (booking.station && stationsId.includes(booking.station)) {
                const stationData = poisDetailsData.find(poi => poi.stationId === booking.station);
                newBooking = { ...newBooking, stationName: stationData.name, stationAddress: stationData.address };
            }
            if (booking.modelId && modelsId.includes(booking.modelId)) {
                const modelData = modelsDetailsResults.find(m => m.id === booking.modelId);
                newBooking = { ...newBooking, modelIconsUrl: modelData.iconsUrl, modelName: modelData.name };
            }
            this[indexBooking] = newBooking;
        }, bookingsResult);

        yield put(
            fetchSBRequestsSuccessful({
                data: bookingsResult,
            })
        );
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(fetchSBRequestsFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
