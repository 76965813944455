import { call, delay, put } from 'redux-saga/effects';

import BookingClient from '../../api/BookingClient';
import { fetchSBRequestByIDFailed, fetchSBRequestByIDSuccessful } from '../../redux/bookings/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*(action) {
    try {
        yield delay(1000);
        const bookingResult = yield call(BookingClient.getBookingById, action.id.id);
        yield put(
            fetchSBRequestByIDSuccessful({
                data: bookingResult,
            })
        );
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(fetchSBRequestByIDFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
