import { call, put, all, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { billingListSuccessful, billingListFailed } from '../../redux/billing/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import getUser from '../user/get';
import { meSelector, listProfileSelector } from '../../selectors/user';
import { profileSelector, dateFilterSelector } from '../../selectors/billing';

export default function*() {
    try {
        yield call(getUser);
        const me = yield select(meSelector);
        const profileId = yield select(profileSelector);
        const profiles = yield select(listProfileSelector);
        const dateFilter = yield select(dateFilterSelector);

        if (!me) {
            yield call(getUser);
        }
        const selectedProfile = profiles.find(profile => profile.id === profileId);
        const selectedProfileType = selectedProfile ? selectedProfile.type : 'Single';
        let datas = null;
        if (dateFilter) {
            const { startDate, endDate } = dateFilter;
            datas = yield all([
                call(UserClient.fetchMyTrips, selectedProfileType, profileId, startDate, endDate),
                call(UserClient.fetchMyProducts, startDate, endDate),
                call(UserClient.fetchMyOldInvoices, startDate, endDate),
            ]);
        } else {
            datas = yield all([
                call(UserClient.fetchMyTrips, selectedProfileType, profileId),
                call(UserClient.fetchMyProducts),
                call(UserClient.fetchMyOldInvoices),
            ]);
        }
        yield put(
            billingListSuccessful({
                trips: datas[0],
                others: datas[1],
                old: datas[2],
                isFiltered: !!dateFilter,
            })
        );
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(billingListFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
