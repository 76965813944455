import {
    GET_INVOICES_REPLAY_DATA_SUCCESS,
    GET_INVOICES_REPLAY_DATA_FAILURE,
    SET_SHOULD_REDIRECT_TO_INVOICES,
    SET_INVOICE_REPLAY_FETCHING,
    SET_IS_PAYMENT_PROCESSING,
    SET_DISPLAY_REDIRECT_TO_INVOICES,
    CLEAR_INVOICES_REPLAY,
} from './types';

const initState = {
    fetching: true,
    data: {},
    shouldRedirectToInvoices: false,
    isPaymentProcessing: false,
    displayRedirectToInvoices: false,
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case GET_INVOICES_REPLAY_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
            };

        case GET_INVOICES_REPLAY_DATA_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case SET_SHOULD_REDIRECT_TO_INVOICES:
            return {
                ...state,
                shouldRedirectToInvoices: action.payload,
            };

        case SET_INVOICE_REPLAY_FETCHING:
            return {
                ...state,
                fetching: action.payload,
            };

        case SET_IS_PAYMENT_PROCESSING:
            return {
                ...state,
                isPaymentProcessing: action.payload,
            };

        case SET_DISPLAY_REDIRECT_TO_INVOICES:
            return {
                ...state,
                displayRedirectToInvoices: action.payload,
            };

        case CLEAR_INVOICES_REPLAY:
            return {
                ...initState,
            };

        default:
            return state;
    }
};

export default reducer;
