import { all, takeLatest } from 'redux-saga/effects';

import { LOGIN_REQUEST, LOGOUT_REQUEST, TWO_FA_LOGIN_REQUEST } from '../../redux/login/types';

import { getToken, twoFAloginRequest } from './getToken';
import logout from './logout';

export default function*() {
    yield all([
        takeLatest(LOGIN_REQUEST, getToken),
        takeLatest(LOGOUT_REQUEST, logout),
        takeLatest(TWO_FA_LOGIN_REQUEST, twoFAloginRequest),
    ]);
}
