import { call, put, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { freeminutesListSuccessful, freeminutesListFailed } from '../../redux/freeminutes/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import getUser from '../user/get';
import { meSelector, entityIdSelector } from '../../selectors/user';

export default function*() {
    try {
        const me = yield select(meSelector);
        if (!me || !me.entityId) {
            yield call(getUser);
        }
        // if the user has no entityId (multiprofile fleets),
        // then we use the entityId of the personal profile (single)
        const entityId = yield select(entityIdSelector);
        const datas = yield call(UserClient.fetchMyPromoCodes, entityId);
        yield put(freeminutesListSuccessful(datas));
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(freeminutesListFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
