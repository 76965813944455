import { call, put, select } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import {
    loginSuccessful,
    loginFailed,
    loginOpenPinCodeModal,
    save2FAuthInfo,
    passwordTooWeak,
} from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';
import { twoFAuthInfoSelector } from '../../selectors/login';

export function* twoFAloginRequest({ payload }) {
    const twoFAuthInfo = yield select(twoFAuthInfoSelector);

    const params = {
        pinCode: payload.pinCode,
        sessionId: twoFAuthInfo.sessionId,
        customUrl: twoFAuthInfo.url,
    };

    try {
        yield call(UserClient.fetchToken2FA, params);
        yield put(loginSuccessful());
    } catch (err) {
        const {
            data: { invalid_reason: invalidReason },
        } = err;

        if (invalidReason === 'weak password') {
            yield put(passwordTooWeak(true));
        } else {
            yield put(messagesShow('invalidPinCode', 'error'));
        }

        yield put(loginOpenPinCodeModal(false));
        yield put(loginFailed());
    }
}

export function* getToken({ payload: { login, password, reCAPTCHAsiteKey } }) {
    const { grecaptcha } = window;

    if (grecaptcha) {
        try {
            const recaptchaToken = yield call(grecaptcha.enterprise.execute, reCAPTCHAsiteKey, { action: 'login' });
            yield call(UserClient.fetchToken, login, password, recaptchaToken, reCAPTCHAsiteKey);
            yield put(loginSuccessful());
        } catch (err) {
            const {
                data: { error, invalid_reason: invalidReason, session_id: sessionId, url },
            } = err;

            if (err && err.data && error) {
                if (error === '2FA') {
                    // Open modal to get sms pin code
                    yield put(save2FAuthInfo({ sessionId, url }));
                    yield put(loginOpenPinCodeModal(true));
                } else if (invalidReason && invalidReason !== 'weak password') {
                    // The user hasn't provided any phone number
                    yield put(loginFailed());
                    yield put(messagesShow(invalidReason, 'error'));
                } else if (invalidReason && invalidReason === 'weak password') {
                    yield put(loginFailed());
                    yield put(passwordTooWeak(true));
                } else if (error) {
                    yield put(loginFailed());
                    yield put(messagesShow(error, 'error'));
                }
            } else {
                console.error('Error :', err);
                yield put(loginFailed());
                yield put(messagesShow('invalidLogin', 'error'));
            }
        }

        return;
    }

    // OLD LOGIC
    try {
        yield call(UserClient.fetchToken, login, password);
        yield put(loginSuccessful());
    } catch (err) {
        const {
            data: { invalid_reason: invalidReason },
        } = err;

        if (invalidReason === 'weak password') {
            yield put(passwordTooWeak(true));
        } else {
            yield put(messagesShow('invalidLogin', 'error'));
        }

        yield put(loginFailed());
    }
}
