import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { freeminutesAddFailed, freeminutesListRequest, freeMinutesClear } from '../../redux/freeminutes/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload }) {
    try {
        yield call(UserClient.redeemPromoCode, payload);
        yield put(messagesShow('PromoCodeRedeemOKMessage', 'success'));
        yield put(freeminutesListRequest());
        yield put(freeMinutesClear());
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else if (error.code === 409 && error.message.code === 'promocodeConflict') {
            yield put(messagesShow('promocodeConflictErrorMessage', 'error'));
            yield put(freeminutesAddFailed());
        } else {
            yield put(messagesShow('PromoCodeRedeemFailedMessage', 'error'));
            yield put(freeminutesAddFailed());
        }
    }
}
