import { all, takeLatest } from 'redux-saga/effects';

import {
    NEW_BOOKING_FETCH_MODELS_BY_STATION,
    NEW_BOOKING_CREATE_SB_REQUEST,
    BOOKINGS_FETCH_SB_REQUESTS,
    BOOKINGS_CANCEL_SB_REQUEST,
    BOOKINGS_FETCH_PAID_REQUESTS,
    BOOKINGS_FETCH_CITIES_REQUEST,
    BOOKINGS_FETCH_SB_REQUEST_BY_ID,
    GET_MODEL_ASSETS,
} from '../../redux/bookings/types';

import fetchModelsByStation from './fetchModelsByStation';
import createSBRequest from './createSBRequest';
import fetchSBRequests from './fetchSBRequests';
import cancelSBRequest from './cancelSBRequest';
import fetchPaidSBRequests from './fetchPaidSBRequests';
import fetchCities from './fetchCitiesRequest';
import fetchSBRequestByID from './fetchSBRequestByID';
import fetchModelAssets from './fetchModelAssets';

export default function*() {
    yield all([
        takeLatest(NEW_BOOKING_FETCH_MODELS_BY_STATION, fetchModelsByStation),
        takeLatest(NEW_BOOKING_CREATE_SB_REQUEST, createSBRequest),
        takeLatest(GET_MODEL_ASSETS, fetchModelAssets),
        takeLatest(BOOKINGS_FETCH_SB_REQUESTS, fetchSBRequests),
        takeLatest(BOOKINGS_FETCH_SB_REQUEST_BY_ID, fetchSBRequestByID),
        takeLatest(BOOKINGS_CANCEL_SB_REQUEST, cancelSBRequest),
        takeLatest(BOOKINGS_FETCH_PAID_REQUESTS, fetchPaidSBRequests),
        takeLatest(BOOKINGS_FETCH_CITIES_REQUEST, fetchCities),
    ]);
}
