import { call, put, select } from 'redux-saga/effects';

import BookingClient from '../../api/UserClient';
import { updateNewBookingData } from '../../redux/bookings/actions';

import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

import { userGetRequest } from '../../redux/user/actions';

import { newBookingDataSelector } from '../../selectors/bookings';
import { displayMessageForBookingError } from '../../helpers/bookings';

export default function*() {
    try {
        const bookingData = yield select(newBookingDataSelector);
        const { selectedCityId } = bookingData;

        const payload = {
            cityId: selectedCityId,
        };

        yield call(BookingClient.agreeToCityTerms, payload);

        yield put(updateNewBookingData({ name: 'selectedCityTermsAccepted', value: true }));

        yield put(userGetRequest());
    } catch (error) {
        console.error('agreeToCityTerms', error);
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            let errorMessage = 'internalServerError';

            if (error && error.message) {
                if (error.message.message) {
                    errorMessage = displayMessageForBookingError(error.message.message);
                }
            }

            yield put(messagesShow(errorMessage, 'error'));
        }
    }
}
