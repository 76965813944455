import { call, put } from 'redux-saga/effects';

import VehicleClient from '../../api/VehicleClient';
import {
    setVehicleOngoingTripPINCodeAction,
    setVehicleOngoingTripPINCodeErrorAction,
} from '../../redux/fuelCard/actions';

export function* getVehicleOngoingTripPINCodeSaga({ payload }) {
    try {
        const PINCode = yield call(VehicleClient.getVehicleOngoingTripPINCodeEndpoint, payload);

        if (PINCode) {
            yield put(setVehicleOngoingTripPINCodeAction(PINCode));
        }
    } catch (error) {
        if (error && error.message) {
            let message = 'PINCodeErrorDefault';

            if (error.message.indexOf('not in active trip') !== -1) {
                message = 'PINCodeErrorNoActiveTrip';
            } else if (error.message.indexOf('vehicle has more than one') !== -1) {
                message = 'PINCodeErrorMultipleRFID';
            } else if (error.message.indexOf('No card found') !== -1) {
                message = 'PINCodeErrorNoRFID';
            }

            yield put(setVehicleOngoingTripPINCodeErrorAction(message));
        } else {
            console.error('[ERROR][SAGA] getVehicleOngoingTripPINCodeSaga', error);
        }
    }
}
