import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';

import { messagesShow } from '../../redux/messages/actions';
import { requestAdyenSynchronizeSuccessful } from '../../redux/app/actions';
import { getSessionStorageItem, setSessionStorageItem } from '../../helpers/LocalStorage';

export default function*({ payload: redirectResult }) {
    try {
        const entityId = getSessionStorageItem('entityId');
        const setupIntentId = getSessionStorageItem('setupIntentId');

        const { status } = yield call(UserClient.synchronizeSetupIntent, entityId, setupIntentId, redirectResult);

        yield put(requestAdyenSynchronizeSuccessful(status));
        if (status === 'SUCCEEDED') {
            yield put(messagesShow('PaymentSuccess', 'success'));
        } else {
            yield put(messagesShow('PaymentError', 'error'));
        }

        setSessionStorageItem('entityId', null);
        setSessionStorageItem('setupIntentId', null);
    } catch (error) {
        console.error('PaymentError', error);
        yield put(messagesShow('PaymentError', 'error'));
    }
}
