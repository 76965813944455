import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { userGetRequest, userProfileUpdateSuccessful, userProfileUpdateFailed } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload }) {
    try {
        const newPayload = payload;

        if (payload.financialInformation && !payload.financialInformation.marketReference) {
            newPayload.financialInformation = {
                ...newPayload.financialInformation,
                marketReference: 'ITALY',
            };
        }

        yield call(UserClient.updateProfile, newPayload);
        yield put(userProfileUpdateSuccessful());
        yield put(userGetRequest());
        yield put(messagesShow('editOk', 'success'));
    } catch (error) {
        if (error.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(userProfileUpdateFailed());
            yield put(messagesShow('editNotOk', 'error'));
        }
    }
}
