import { call, put, select } from '@redux-saga/core/effects';
import UserClient from '../../api/UserClient';
import { packagesRequestFailed, packagesRequestSuccessful, startPackagesRequest } from '../../redux/packages/actions';
import { messagesShow } from '../../redux/messages/actions';
import { meSelector } from '../../selectors/user';
import getUser from '../user/get';
import { logoutRequest } from '../../redux/login/actions';

export default function*() {
    try {
        yield put(startPackagesRequest());
        let me = yield select(meSelector);
        if (!me || !me.profile) {
            yield call(getUser);
            me = yield select(meSelector);
        }

        const packages = yield call(UserClient.getSystemCreditsPackages);
        yield put(packagesRequestSuccessful(packages, me.profile.currency));
    } catch (err) {
        if (err.code === 401) {
            yield put(logoutRequest());
        } else {
            yield put(packagesRequestFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
