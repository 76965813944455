import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
    loadingContent: {
        textAlign: 'center',
    },
});

const Loading = ({ classes: { loadingContent } }) => (
    <Dialog
        fullWidth
        onClose={(event, reason) => (reason === 'backdropClick' || reason === 'escapeKeyDown') && null}
        disableEscapeKeyDown
        maxWidth="xs"
        open
    >
        <DialogContent className={loadingContent}>
            <CircularProgress />
        </DialogContent>
    </Dialog>
);

Loading.propTypes = {
    classes: PropTypes.shape({
        loadingContent: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(Loading);
