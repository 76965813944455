import { put } from 'redux-saga/effects';

import OpenIDClient from '../../api/OpenIDClient';
import { clear as clearLogin } from '../../redux/login/actions';
import { clear as clearFreeMinutes } from '../../redux/freeminutes/actions';
import { clear as clearUser } from '../../redux/user/actions';
import { clearInvoicesReplay } from '../../redux/invoicesReplay/actions';
import { resetStripe } from '../../redux/app/actions';

export default function*() {
    OpenIDClient.authenticatedOpenID.logout();
    yield put(clearLogin());
    yield put(clearFreeMinutes());
    yield put(clearUser());
    yield put(clearInvoicesReplay());
    yield put(resetStripe());
}
