import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from '.';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    //  Dev remote devtools
    const composeEnhancers = composeWithDevTools({
        trace: true,
    });
    const enhancer = composeEnhancers(
        applyMiddleware(sagaMiddleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    );

    const store = createStore(reducer, enhancer);

    return {
        store,
        run: sagaMiddleware.run,
    };
};

export default configureStore;
