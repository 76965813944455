import { call, put, select } from 'redux-saga/effects';

import { entityIdSelector } from '../../selectors/user';
import UserClient from '../../api/UserClient';
import { getPaymentDetailsSuccess, getPaymentDetailsFailure } from '../../redux/user/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*() {
    const entityId = yield select(entityIdSelector);

    try {
        const paymentDetails = yield call(UserClient.getPaymentDetails, entityId);
        yield put(getPaymentDetailsSuccess(paymentDetails));
    } catch (error) {
        yield put(getPaymentDetailsFailure());
        yield put(messagesShow(error.message, 'error'));
    }
}
