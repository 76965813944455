import { all, takeLatest } from 'redux-saga/effects';

import {
    FREE_MINUTES_ADD_REQUEST,
    FREE_MINUTES_LIST_REQUEST,
    CHECK_MULTI_FLEET_AVAILABILITY,
} from '../../redux/freeminutes/types';

import add from './add';
import list from './list';
import { checkMultiFleetAvailabilitySaga } from './checkMultiFleetAvailability';

export default function*() {
    yield all([
        takeLatest(FREE_MINUTES_ADD_REQUEST, add),
        takeLatest(FREE_MINUTES_LIST_REQUEST, list),
        takeLatest(CHECK_MULTI_FLEET_AVAILABILITY, checkMultiFleetAvailabilitySaga),
    ]);
}
