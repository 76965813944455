import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import UserClient from '../../api/UserClient';
import { CARD, SEPA } from '../../components/Account/Constants';
import { setSessionStorageItem } from '../../helpers/LocalStorage';
import { getPubKeySuccess, resetStripeClientSecret, setupStripeIntentSuccessful } from '../../redux/app/actions';
import {
    GET_PUB_KEY,
    REQUEST_ADYEN_SYNCHRONIZE,
    REQUEST_STRIPE_SYNCHRONIZE,
    SETUP_ADYEN_INTENT,
    SETUP_STRIPE_INTENT,
} from '../../redux/app/types';
import { messagesShow } from '../../redux/messages/actions';
import { getPubKeyIntent } from '../../redux/user/actions';
import { USER_GET_PUBKEY_INTENT } from '../../redux/user/types';
import { setupIntentIdSelector } from '../../selectors/app';
import { entityIdSelector } from '../../selectors/user';
import getUser from '../user/get';

import adyenSetupIntent from './adyenSetupIntent';
import requestAdyenSynchronize from './requestAdyenSynchronize';

const getBackendPaymentMethod = paymentMethod => {
    switch (paymentMethod) {
        case CARD:
            return 'CREDIT_CARD';
        case SEPA:
            return 'SEPA';
        default:
            throw new Error('Invalid payment method:', paymentMethod);
    }
};

export function* stripeSetupIntentSaga({ paymentMethod }) {
    const backendPaymentMethod = getBackendPaymentMethod(paymentMethod);
    let entityId = yield select(entityIdSelector);
    if (!entityId) {
        yield call(getUser);
        entityId = yield select(entityIdSelector);
        // throw new Error('[SAGA][stripeSetupIntentSaga] entityId is obligatory!');
    }
    try {
        const response = yield call(UserClient.onSetupIntent, { entityId, paymentMethodType: backendPaymentMethod });
        const { pspPublishableKey, pspClientSecret, id } = response;
        setSessionStorageItem('setupIntentId', id);
        yield put(
            setupStripeIntentSuccessful({ pubKey: pspPublishableKey, clientSecret: pspClientSecret, setupIntentId: id })
        );
    } catch (error) {
        yield put(resetStripeClientSecret());
        yield put(messagesShow('internalServerError', 'error'));
    }
}

function* requestStripeSynchronized() {
    const setupIntentId = yield select(setupIntentIdSelector);
    if (!setupIntentId) {
        return;
    }
    try {
        let entityId = yield select(entityIdSelector);
        if (!entityId) {
            yield call(getUser);
            entityId = yield select(entityIdSelector);
        }
        const { status } = yield call(UserClient.synchronizeSetupIntent, entityId, setupIntentId);

        if (status === 'SUCCEEDED') {
            yield put(messagesShow('PaymentSuccess', 'success'));
        } else {
            yield put(messagesShow('PaymentError', 'error'));
        }
        setSessionStorageItem('setupIntentId');
    } catch (error) {
        console.error('PaymentError', error);
        yield put(messagesShow('PaymentError', 'error'));
    } finally {
        setSessionStorageItem('setupIntentId');
    }
}

function* pubKeyRequest() {
    try {
        const pubKey = yield call(UserClient.getPublishableKey);
        yield put(getPubKeySuccess({ pubKey }));
    } catch (error) {
        console.error('[SAGA][pubKeyRequest] Error', error);
        yield put(messagesShow('internalServerError', 'error'));
    }
}

export default function*() {
    yield all([
        takeLatest(SETUP_STRIPE_INTENT, stripeSetupIntentSaga),
        takeLatest(SETUP_ADYEN_INTENT, adyenSetupIntent),
        takeLatest(REQUEST_ADYEN_SYNCHRONIZE, requestAdyenSynchronize),
        takeLatest(GET_PUB_KEY, pubKeyRequest),
        takeLatest(REQUEST_STRIPE_SYNCHRONIZE, requestStripeSynchronized),
        takeLatest(USER_GET_PUBKEY_INTENT, getPubKeyIntent),
    ]);
}
