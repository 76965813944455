import React from 'react';
import { call } from 'redux-saga/effects';
import Account from 'mdi-material-ui/Account';
import Briefcase from 'mdi-material-ui/Briefcase';
import { mainColors } from '../constants/Colors';

export const CONST_PROFILE_TYPE_SINGLE = 'Single';
export const CONST_PROFILE_TYPE_BUSINESS = 'Business';
export const userProfilesTypes = ['single', 'business'];
export const defaultFreePlan = {
    id: '0',
    name: 'Free Plan',
    amount: '0',
};
export const SUBSCRIPTION_STATUS_ACTIVE = 'Active';
export const SUBSCRIPTION_STATUS_NOT_ACTIVE = 'Not active';

export function* uploadDocumentAttachementHelper(UserClient, { uploadQueue }) {
    const { file, endpoint } = uploadQueue;

    if (endpoint && endpoint.url) {
        const data = new FormData();
        data.append('file', file);
        yield call(UserClient.uploadDocument, endpoint, data);
    } else {
        return yield Promise.resolve(false);
    }

    return yield Promise.resolve(true);
}

export const serviceIdToServiceName = (allServices, serviceId) => {
    let serviceName = '-';
    if (allServices) {
        allServices.forEach(service => {
            if (service.id === serviceId) {
                serviceName = service.name;
            }
            return true;
        });
    }
    return serviceName;
};

export const getProfileStatusIcon = type => {
    switch (type && type.toLowerCase()) {
        case 'business':
            return <Briefcase style={{ width: '20px', height: '24px' }} />;
        case 'single':
            return <Account />;
        default:
            return <Account />;
    }
};

export const sortArrayByProfileType = array => {
    const sorting = [...userProfilesTypes, 'family'];
    return (
        array &&
        array
            .map(item => {
                const n = item.type && sorting.indexOf(item.type.toLowerCase());
                return [n, item];
            })
            .sort((a, b) => a[0] - b[0])
            .map(j => j[1])
    );
};

export const getProfileStatusInfo = status => {
    switch (status) {
        case 'APPROVED':
            return { label: 'approved', color: mainColors.green };
        case 'MOP_MISSING':
            return { label: 'mopMissing', color: mainColors.red };
        case 'MOP_EXPIRED':
            return { label: 'mopExpired', color: mainColors.red };
        case 'MOP_INVALID':
            return { label: 'mopInvalid', color: mainColors.red };
        case 'USER_REJECTED':
            return { label: 'profileRejected', color: mainColors.red };
        case 'USER_SUSPENDED':
            return { label: 'profileSuspended', color: mainColors.red };
        case 'USER_ARCHIVED':
            return { label: 'profileArchived', color: mainColors.red };
        case 'ENTITY_PENDING':
            return { label: 'entityPending', color: mainColors.red };
        case 'ENTITY_SUSPENDED':
            return { label: 'entitySuspended', color: mainColors.red };
        case 'ENTITY_REJECTED':
            return { label: 'entityRejected', color: mainColors.red };
        case 'ENTITY_ARCHIVED':
            return { label: 'entityArchived', color: mainColors.red };

        default:
            return { label: status, color: mainColors.grey };
    }
};

export const getServiceStatusInfo = status => {
    switch (status) {
        case 'APPROVED':
            return { label: 'approved', color: mainColors.green };
        case 'DOC_MISSING':
            return { label: 'docMissing', color: mainColors.yellow };
        case 'DOC_PENDING_REVIEW':
            return { label: 'docPendingReview', color: mainColors.blue };
        case 'DOC_EXPIRED':
            return { label: 'docExpired', color: mainColors.red };
        case 'DOC_INVALID':
            return { label: 'docInvalid', color: mainColors.red };
        case 'SERVICE_REG_PENDING':
            return { label: 'pending', color: mainColors.blue };
        case 'SERVICE_REG_INCOMPLETE':
            return { label: 'incomplete', color: mainColors.grey };
        case 'SERVICE_REG_SUSPENDED':
            return { label: 'suspended', color: mainColors.red };
        case 'SERVICE_REG_REJECTED':
            return { label: 'rejected', color: mainColors.red };
        case 'SERVICE_REG_UNREGISTERED':
            return { label: 'unregistered', color: mainColors.grey };
        case 'TOU_UNACCEPTED':
            return { label: 'touUnaccepted', color: mainColors.yellow };

        default:
            return { label: status, color: mainColors.grey };
    }
};

export const sortById = (a, b) => (a.id > b.id ? 1 : -1);

export const needRegRedirection = (api, profiles) => {
    const listOfStatusesForRedirection =
        (api && api.serviceStatusesForRedirection && api.serviceStatusesForRedirection.split(',')) || [];
    const skipB2CRegRedirection = !!(api && api.skipB2CRegRedirection);

    const isBusinessProfileExisting = profiles.some(p => p.type && p.type.toLowerCase() === 'business');
    if (skipB2CRegRedirection && isBusinessProfileExisting) {
        return false;
    }

    let shouldRedirectCount = 0;
    profiles.forEach(profile => {
        if (profile.services.length > 0) {
            const shouldRedirect = profile.services.some(service =>
                listOfStatusesForRedirection.includes(service.status)
            );
            if (shouldRedirect) {
                shouldRedirectCount += 1;
            }
        }
    });

    if (shouldRedirectCount > 0) {
        return true;
    }
    return false;
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
