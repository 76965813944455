import React from 'react';
import PropTypes from 'prop-types';
import tagHelper from './tagHelper';

class GoogleTagManager extends React.Component {
    addJsToHead = iframe => {
        const s = document.createElement('script');

        s.type = 'text/javascript';
        s.innerHTML = iframe;
        document.getElementsByTagName('head')[0].appendChild(s);
    };

    addNoScriptToBody = jsCode => {
        const s = document.createElement('noscript');
        s.innerText = jsCode;
        document.getElementsByTagName('body')[0].appendChild(s);
    };

    render() {
        const { gtmId, additionalEvents, previewVariables, scheme } = this.props;
        let { dataLayerName } = this.props;

        // must contain gtmId or render nothing
        if (!gtmId) {
            return <></>;
        }

        const gtm = tagHelper({
            id: gtmId,
            dataLayerName: dataLayerName || 'dataLayer',
            additionalEvents: additionalEvents || {},
            previewVariables: previewVariables || false,
            scheme: scheme || 'https:',
        });

        dataLayerName = dataLayerName || 'dataLayer';

        // will exists only if gtmId is set
        if (!window[dataLayerName]) {
            this.addJsToHead(gtm.scriptAsText());
            this.addNoScriptToBody(gtm.noScriptAsHTML());
        }

        return <></>;
    }
}

GoogleTagManager.propTypes = {
    gtmId: PropTypes.string,
    dataLayerName: PropTypes.string,
    additionalEvents: PropTypes.shape({}),
    previewVariables: PropTypes.string,
    scheme: PropTypes.string,
};

export default GoogleTagManager;
