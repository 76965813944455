import {
    CLEAR,
    LOGIN_REQUEST,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILED,
    LOGIN_SET_AUTHENTICATE,
    LOGOUT_REQUEST,
    TWO_FA_LOGIN_REQUEST,
    LOGIN_OPEN_PINCODE_MODAL,
    LOGIN_SAVE_2FA_AUTH_INFO,
    PASSWORD_TOO_WEAK,
} from './types';

export const clear = () => ({ type: CLEAR });
export const loginRequest = (login, password, reCAPTCHAsiteKey) => ({
    type: LOGIN_REQUEST,
    payload: {
        login,
        password,
        reCAPTCHAsiteKey,
    },
});
export const loginSuccessful = () => ({ type: LOGIN_SUCCESSFUL });
export const loginFailed = () => ({ type: LOGIN_FAILED });
export const loginSetAuthenticate = isAuthenticate => ({ type: LOGIN_SET_AUTHENTICATE, payload: isAuthenticate });
export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const twoFAloginRequest = payload => ({ type: TWO_FA_LOGIN_REQUEST, payload });
export const loginOpenPinCodeModal = payload => ({ type: LOGIN_OPEN_PINCODE_MODAL, payload });
export const save2FAuthInfo = payload => ({ type: LOGIN_SAVE_2FA_AUTH_INFO, payload });
export const passwordTooWeak = payload => ({ type: PASSWORD_TOO_WEAK, payload });
