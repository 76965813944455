import {
    ACTION_GET_VEHICLE_ONGOING_TRIP_PIN_CODE,
    ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_SUCCESS,
    ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_ERROR,
} from './types';

const initState = {
    fetching: false,
    vehicleOngoingTripPINCode: '',
    error: '',
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_GET_VEHICLE_ONGOING_TRIP_PIN_CODE:
            return {
                ...initState,
                fetching: true,
            };

        case ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_SUCCESS:
            return {
                ...initState,
                vehicleOngoingTripPINCode: action.payload,
            };

        case ACTION_SET_VEHICLE_ONGOING_TRIP_PIN_CODE_ERROR:
            return {
                ...initState,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
