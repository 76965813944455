export const CLEAR = '@freeminutes/CLEAR';
export const FREE_MINUTES_LIST_REQUEST = '@freeminutes/FREE_MINUTES_LIST_REQUEST';
export const FREE_MINUTES_LIST_SUCCESSFUL = '@freeminutes/FREE_MINUTES_LIST_SUCCESSFUL';
export const FREE_MINUTES_LIST_FAILED = '@freeminutes/FREE_MINUTES_LIST_FAILED';
export const FREE_MINUTES_ADD_REQUEST = '@freeminutes/FREE_MINUTES_ADD_REQUEST';
export const FREE_MINUTES_ADD_FAILED = '@freeminutes/FREE_MINUTES_ADD_FAILED';
export const CHECK_MULTI_FLEET_AVAILABILITY = '@freeminutes/CHECK_MULTI_FLEET_AVAILABILITY';
export const CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS = '@freeminutes/CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS';
export const CHECK_MULTI_FLEET_AVAILABILITY_FAILURE = '@freeminutes/CHECK_MULTI_FLEET_AVAILABILITY_FAILURE';
export const FREE_MINUTES_CLEAR = '@freeminutes/FREE_MINUTES_CLEAR';
