import { call, put, select } from 'redux-saga/effects';

import { fetchCitiesRequestSuccessful, fetchCitiesRequestFailed } from '../../redux/bookings/actions';
import { messagesShow } from '../../redux/messages/actions';
import UserClient from '../../api/UserClient';
import BookingClient from '../../api/BookingClient';
import { isAuthenticateSelector } from '../../selectors/login';

export default function*() {
    try {
        const isUserAuthenticated = yield select(isAuthenticateSelector);

        const api = BookingClient.getConf();
        const anonymousBrowsing = (api.scheduledBooking && api.scheduledBooking.anonymousBrowsing !== false) || false;

        if (!isUserAuthenticated && anonymousBrowsing) {
            yield call(UserClient.fetchTokenAnonymous);
        }
        const allCities = yield call(BookingClient.getCities, isUserAuthenticated);

        yield put(fetchCitiesRequestSuccessful(allCities));
    } catch (error) {
        console.error('Error in fetchCitiesRequest', error);
        yield put(fetchCitiesRequestFailed());
        yield put(messagesShow('internalServerError', 'error'));
    }
}
