export default class ApiClient {
    get = (openIDClient, apiUrl, customResolve = this.customResolve, customReject = this.customReject, config) =>
        new Promise((resolve, reject) => {
            openIDClient.instanceAxios
                .get(apiUrl, config)
                .then(customResolve(resolve))
                .catch(customReject(reject));
        });

    put = (openIDClient, apiUrl, payload, customResolve = this.customResolve, customReject = this.customReject) =>
        new Promise((resolve, reject) => {
            openIDClient.instanceAxios
                .put(apiUrl, payload)
                .then(customResolve(resolve))
                .catch(customReject(reject));
        });

    post = (openIDClient, apiUrl, payload, customResolve = this.customResolve, customReject = this.customReject) =>
        new Promise((resolve, reject) => {
            openIDClient.instanceAxios
                .post(apiUrl, payload)
                .then(customResolve(resolve))
                .catch(customReject(reject));
        });

    delete = (openIDClient, apiUrl, payload, customResolve = this.customResolve, customReject = this.customReject) =>
        new Promise((resolve, reject) => {
            openIDClient.instanceAxios
                .delete(apiUrl, payload)
                .then(customResolve(resolve))
                .catch(customReject(reject));
        });

    customResolve = resolve => response => resolve(response.data);

    customReject = reject => error => {
        if (error.response) {
            if (error.response.status === 401) {
                reject({ code: 401, message: 'Unauthorised' });
            } else {
                reject(this.buildReply(error.response.status, error.response.data || 'Server error'));
            }
        } else if (error.request) {
            reject(this.buildReply(500, 'No response received from the server'));
        } else {
            reject(this.buildReply(500, `Unable to make HTTP request: ${error.message}`));
        }
    };

    buildReply = (code, message) => ({ code, message });
}
