import {
    CLEAR,
    FREE_MINUTES_LIST_REQUEST,
    FREE_MINUTES_LIST_SUCCESSFUL,
    FREE_MINUTES_LIST_FAILED,
    FREE_MINUTES_ADD_REQUEST,
    FREE_MINUTES_ADD_FAILED,
    CHECK_MULTI_FLEET_AVAILABILITY,
    CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS,
    FREE_MINUTES_CLEAR,
    CHECK_MULTI_FLEET_AVAILABILITY_FAILURE,
} from './types';

export const clear = () => ({ type: CLEAR });
export const freeminutesAddRequest = payload => ({ type: FREE_MINUTES_ADD_REQUEST, payload });
export const freeminutesAddFailed = () => ({ type: FREE_MINUTES_ADD_FAILED });
export const freeminutesListRequest = () => ({ type: FREE_MINUTES_LIST_REQUEST });
export const freeminutesListSuccessful = datas => ({ type: FREE_MINUTES_LIST_SUCCESSFUL, payload: datas });
export const freeminutesListFailed = () => ({ type: FREE_MINUTES_LIST_FAILED });
export const checkMultiFleetAvailability = payload => ({ type: CHECK_MULTI_FLEET_AVAILABILITY, payload });
export const checkMultiFleetAvailabilitySuccess = payload => ({
    type: CHECK_MULTI_FLEET_AVAILABILITY_SUCCESS,
    payload,
});
export const checkMultiFleetAvailabilityFailure = () => ({ type: CHECK_MULTI_FLEET_AVAILABILITY_FAILURE });
export const freeMinutesClear = () => ({ type: FREE_MINUTES_CLEAR });
