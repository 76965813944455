import OpenIDClient from './OpenIDClient';
import ApiClient from './ApiClient';

class StationClient extends ApiClient {
    init = apiConf => {
        this.apiConf = apiConf;
    };

    getStationInformation = () =>
        this.get(OpenIDClient.authenticatedOpenID, `${this.apiConf.baseEndpoint}mapping/layers`);

    // cityId can be any IDs... and it still gonna work
    getStationOpeningHours = isUserAuthenticated =>
        this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}zonesDetails/1?showSBStations=true&emptyStations=true`
        );

    getStationInstructionAssetsEndpoint = (stationId, isUserAuthenticated = true) =>
        this.get(
            !isUserAuthenticated && this.apiConf.scheduledBooking.anonymousBrowsing !== false
                ? OpenIDClient.anonymousOpenID
                : OpenIDClient.authenticatedOpenID,
            `${this.apiConf.baseEndpoint}stations/${stationId}/assets`
        );
}

export default new StationClient();
