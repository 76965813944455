import {
    ACTION_GET_STATION_INFORMATION,
    ACTION_SET_STATION_INFORMATION_SUCCESS,
    ACTION_SET_STATION_INFORMATION_FAILURE,
    ACTION_GET_STATION_ACCESS_INSTRUCTIONS,
    ACTION_SET_STATION_ACCESS_INSTRUCTIONS,
} from './types';

export const getStationInformationAction = payload => ({
    type: ACTION_GET_STATION_INFORMATION,
    payload,
});

export const setStationInformationSuccessAction = payload => ({
    type: ACTION_SET_STATION_INFORMATION_SUCCESS,
    payload,
});

export const setStationInformationFailureAction = payload => ({
    type: ACTION_SET_STATION_INFORMATION_FAILURE,
    payload,
});

export const getStationAccessInstructionsAction = payload => ({
    type: ACTION_GET_STATION_ACCESS_INSTRUCTIONS,
    payload,
});

export const setStationAccessInstructionsAction = payload => ({
    type: ACTION_SET_STATION_ACCESS_INSTRUCTIONS,
    payload,
});
