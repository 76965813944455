import { all, takeLatest, fork } from 'redux-saga/effects';

import {
    USER_GET_REQUEST,
    USER_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_REQUEST,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_SEND_LOST_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_REQUEST,
    USER_FETCH_ALL_PLANS_REQUEST,
    USER_SUBSCRIBE_TO_A_PLAN_REQUEST,
    USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST,
    USER_GET_PAYMENT_DETAILS,
    USER_REQUEST_ACCOUNT_DELETION,
    USER_GET_PROFILE_SERVICES_WITH_STATUS,
    AGREE_TO_TERMS_OF_USE_REQUEST,
} from '../../redux/user/types';

import get from './get';
import update from './update';
import updateProfile from './updateProfile';
import changePassword from './changePassword';
import sendLostPassword from './sendLostPassword';
import resetPassword from './resetPassword';
import uploadDocumentAttachement from './uploadDocumentAttachement';
import saveDocumentForm from './saveDocumentForm';
import fetchAllPlans from './fetchAllPlans';
import subscribeToAPlan from './subscribeToAPlan';
import unsubscribeFromAPlan from './unsubscribeFromAPlan';
import getPaymentDetails from './getPayment';
import requestAccountDeletion from './requestAccountDeletion';
import getUserServicesStatus from './getUserServicesStatus';
import agreeToCityTerms from './agreeToCityTerms';

export default function*() {
    yield all([
        takeLatest(USER_GET_REQUEST, get),
        takeLatest(USER_UPDATE_REQUEST, update),
        takeLatest(USER_PROFILE_UPDATE_REQUEST, updateProfile),
        takeLatest(USER_CHANGE_PASSWORD_REQUEST, changePassword),
        takeLatest(USER_SEND_LOST_PASSWORD_REQUEST, sendLostPassword),
        takeLatest(USER_RESET_PASSWORD_REQUEST, resetPassword),
        takeLatest(USER_FETCH_ALL_PLANS_REQUEST, fetchAllPlans),
        takeLatest(USER_SUBSCRIBE_TO_A_PLAN_REQUEST, subscribeToAPlan),
        takeLatest(USER_UNSUBSCRIBE_FROM_A_PLAN_REQUEST, unsubscribeFromAPlan),
        takeLatest(USER_GET_PAYMENT_DETAILS, getPaymentDetails),
        takeLatest(USER_REQUEST_ACCOUNT_DELETION, requestAccountDeletion),
        takeLatest(USER_GET_PROFILE_SERVICES_WITH_STATUS, getUserServicesStatus),
        takeLatest(AGREE_TO_TERMS_OF_USE_REQUEST, agreeToCityTerms),
        fork(uploadDocumentAttachement),
        fork(saveDocumentForm),
    ]);
}
