import { all } from 'redux-saga/effects';

import auth from './auth';
import billing from './billing';
import freeminutes from './freeminutes';
import user from './user';
import invoicesReplay from './invoicesReplay';
import app from './app';
import packages from './packages';
import bookings from './bookings';
import stations from './stations';
import fuelCard from './fuelCard';

export default function*() {
    yield all([
        auth(),
        billing(),
        freeminutes(),
        user(),
        invoicesReplay(),
        app(),
        packages(),
        bookings(),
        stations(),
        fuelCard(),
    ]);
}
